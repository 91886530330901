import createAxiosClient, { config } from "../utils/api-utils";

class FirebaseTokenApi {
  constructor() {
    this.client = createAxiosClient();
  }
  async createToken(fcmToken) {
    return await this.client.post("/vvh-fcm-tokens", { fcmToken }, config());
  }
}
export default FirebaseTokenApi = new FirebaseTokenApi();
