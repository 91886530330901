import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "./Header.css";
import {
  isAuthenticated,
  isPlanPurchased,
  isChatting,
} from "../../../utils/api-utils";
import { useNavigate } from "react-router-dom";
import Chatting from "../../../Components/Chatting/Chatting.js";

const Header = () => {
  const user = 1;
  const navigate = useNavigate();
  const refreshPage = () => {
    setTimeout(() => {
      window.location.reload(false);
    }, 0);
  };

  const handleLogOut = () => {
    localStorage.clear();
    navigate("/login");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <header>
      <nav className="gla_light_nav gla_transp_nav">
        <div className="container">
          <div className="gla_logo_container clearfix">
            <a href="/">
              <img
                src="/assets/images/logo.png"
                alt=""
                className="gla_logo_rev"
              />
            </a>
          </div>

          <div className="gla_main_menu gla_main_menu_mobile">
            <div className="gla_main_menu_icon">
              <i></i>
              <i></i>
              <i></i>
              <i></i>
              <b>Menu</b>
            </div>
          </div>

          <div
            className="gla_main_menu_content gla_image_bck"
            data-color="rgba(0,0,0,0.9)"
          >
            <div
              className="gla_over"
              data-color="#000"
              data-opacity="0.7"
            ></div>
          </div>

          <div className="gla_main_menu_content_menu gla_wht_txt text-right">
            <div className="container">
              <ul>
                {!isAuthenticated() && (
                  <>
                    <li>
                      <Link to="/about" onClick={refreshPage}>
                        About Us
                      </Link>
                    </li>
                    <li>
                      <Link to="/contact-us" onClick={refreshPage}>
                        Contact Us
                      </Link>
                    </li>
                  </>
                )}
                {isAuthenticated() && (
                  <>
                    <li>
                      <Link to="/search-result" onClick={refreshPage}>
                        Matches
                      </Link>
                    </li>
                    <li>
                      <Link to="/favourite" onClick={refreshPage}>
                        Favourite
                      </Link>
                    </li>
                    <li>
                      <Link to="/request" onClick={refreshPage}>
                        New Request
                      </Link>
                    </li>
                    <li>
                      <Link to="/search" onClick={refreshPage}>
                        Search
                      </Link>
                    </li>
                    <li className="gla_parent">
                      <Link to="/search" onClick={refreshPage}>
                        User Profile
                      </Link>
                      <ul>
                        <li>
                          <Link to="/update-profile" onClick={refreshPage}>
                            {" "}
                            My Profile
                          </Link>
                        </li>
                        <li>
                          <Link to="/file-upload" onClick={refreshPage}>
                            {" "}
                            Change Picture
                          </Link>
                        </li>
                        <li>
                          <Link to="/account-settings" onClick={refreshPage}>
                            {" "}
                            Account Settings
                          </Link>
                        </li>
                        <li>
                          <Link to="/" onClick={handleLogOut}>
                            Logout
                          </Link>
                        </li>
                      </ul>
                    </li>
                  </>
                )}
                {isPlanPurchased() ? (
                  <h3>Chat</h3>
                ) : (
                  <li>
                    <Link to="/update" onClick={refreshPage}>
                      Upgrade Now
                    </Link>
                  </li>
                )}

                {!isAuthenticated() && (
                  <li>
                    <Link to="/login" onClick={refreshPage}>
                      Login
                    </Link>
                  </li>
                )}
              </ul>
            </div>
          </div>

          {isAuthenticated() && (
            <div className="gla_search_block search_match">
              <div className="gla_search_block_link gla_search_parent">
                <Link to="/search" onClick={refreshPage} title="Search">
                  <i className="ti ti-search"></i>{" "}
                </Link>
              </div>
              <div className="gla_search_block_link gla_user_profile">
                <i className="ti ti-user"></i>
                <ul>
                  {/* <li><Link to="/update-profile" onClick={refreshPage} > My Profile</Link></li> */}

                  <li>
                    <Link to="/my-profile" onClick={refreshPage}>
                      {" "}
                      My Profile
                    </Link>
                  </li>
                  <li>
                    <Link to="/file-upload" onClick={refreshPage}>
                      {" "}
                      Change Picture
                    </Link>
                  </li>
                  <li>
                    <Link to="/account-settings" onClick={refreshPage}>
                      {" "}
                      Account Settings
                    </Link>
                  </li>
                  <li>
                    <Link to="/" onClick={handleLogOut}>
                      Logout
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          )}

          <div className="gla_default_menu">
            <ul>
              {!isAuthenticated() && (
                <>
                  <li>
                    <Link to="/about" onClick={refreshPage}>
                      About Us
                    </Link>
                  </li>
                  <li>
                    <Link to="/contact-us" onClick={refreshPage}>
                      Contact Us
                    </Link>
                  </li>
                </>
              )}

              {isAuthenticated() && (
                <>
                  <li>
                    <Link to="/search-result" onClick={refreshPage}>
                      Matches
                    </Link>
                  </li>
                  <li>
                    <Link to="/favourite" onClick={refreshPage}>
                      Favourite
                    </Link>
                  </li>
                  <li>
                    <Link to="/request" onClick={refreshPage}>
                      New Request
                    </Link>
                  </li>
                </>
              )}
              {isPlanPurchased() ? (
                <li>
                  <Link
                    to={{ pathname: "/chatting", state: { user } }}
                    onClick={refreshPage}
                  >
                    Chat
                  </Link>
                </li>
              ) : (
                <li className="highlight-upgrade">
                  <Link to="/update" onClick={refreshPage}>
                    Upgrade Now
                  </Link>
                </li>
              )}
              {!isAuthenticated() && (
                <li>
                  <Link to="/login" onClick={refreshPage}>
                    Login
                  </Link>
                </li>
              )}
            </ul>
          </div>
          {isChatting() && <Chatting chatUser={isChatting()} />}
        </div>
      </nav>
    </header>
  );
};

export default Header;
