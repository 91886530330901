import { useState, useEffect } from "react";

import { Link } from "react-router-dom";

import "./Intrest.css";
import Header from "../../Components/Shared/Header/Header";
import Footer from "../../Components/Shared/Footer/Footer";
import VivahApi from "../../api";
function Intrest() {
  const [file, setFile] = useState("");
  const [imagePreviewUrl, setImagePreviewUrl] = useState("");
  const [imagePreview, setImagePreview] = useState(null);
  const [interestReq, setInterestReq] = useState([]);

  useEffect(() => {
    if (imagePreviewUrl) {
      setImagePreview(<img src={imagePreviewUrl} />);
    } else {
      setImagePreview(
        <div className="previewText">Please select an Image for Preview</div>
      );
    }
  }, [imagePreviewUrl]);

  useEffect(() => {
    const init = async () => {
      const requestRes = await VivahApi.Interest.getRequest();
      setInterestReq(requestRes.data);
    };
    init();
  }, []);

  const _handleSubmit = (e) => {
    e.preventDefault();
    // console.log('handle uploading-', file);
  };

  const handleImageChange = (e) => {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      setFile(file);
      setImagePreviewUrl(reader.result);
    };

    reader.readAsDataURL(file);
  };

  const handleViewClick = async (id, profileId) => {
    const isread = await VivahApi.Interest.updateInterest(id, { isRead: 1 });
    window.location.href = `/profile/${profileId}`;
  };

  const handleReuest = async (id) => {
    const isread = await VivahApi.Interest.updateInterest(id, {
      isRequestAccept: 1,
    });
    alert("request Accepted");
  };
  return (
    <>
      <div className="gla_page" id="gla_page">
        <a href="#gla_page" className="gla_top ti ti-angle-up gla_go"></a>
        <Header />
        <div
          className="gla_page_title gla_image_bck gla_wht_txt"
          data-color="#282828"
        >
          <div className="container text-left">
            <div className="row">
              <div className="col-md-8">
                <h1 className="gla_h1_title">User Request</h1>
              </div>
              <div className="col-md-4">
                <div className="breadcrumbs">
                  <a href="#">Home</a>
                  <span>Request</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section id="gla_content" className="gla_content">
          <section className="gla_section upload">
            <div className="container">
              <div className="cart">
                <div className="cart-table">
                  <div className="row row-title">
                    <div className="col-md-5 col-xs-4 table_head">
                      <span>Name</span>
                    </div>
                    <div className="col-md-2 col-xs-2 table_head">
                      <span>Religion / Caste</span>
                    </div>
                    <div className="col-md-2 col-xs-3 table_head">
                      <span>City</span>
                    </div>
                    <div className="col-md-2 col-xs-2 table_head">
                      <span>Date</span>
                    </div>
                  </div>
                  {interestReq.map((e) => {
                    return (
                      <div className="row">
                        <div className="product_item_line name-item col-md-5 col-sm-5 col-xs-12">
                          <a href="#" className="gla_product_thumbnail">
                            <img alt="" src="http://placehold.it/100x100" />
                          </a>
                          <div className="product-info">
                            <a href="#">
                              {e.requestUser.firstName} {e.requestUser.lastName}
                            </a>
                            <p>
                              {e.requestUser?.profile?.age} /{" "}
                              {e.requestUser?.profile?.height?.height}
                            </p>
                          </div>
                        </div>
                        <div className="product_item_line price-item col-md-2 col-sm-2 col-xs-3">
                          <span className="cart-price">
                            {e.requestUser?.profile?.religion?.name || "NA"} /{" "}
                            {e.requestUser?.profile?.caste?.name || "NA"}
                          </span>
                        </div>
                        <div className="product_item_line qty-item col-md-2 col-sm-2 col-xs-3">
                          <div className="add-to-cart">
                            {e.requestUser?.profile?.city?.nameOfCity || "NA"}
                          </div>
                        </div>
                        <div className="product_item_line price-item col-md-2 col-sm-2 col-xs-3">
                          <span className="cart-price">
                            {new Date(e.dateTime).toLocaleDateString()}
                          </span>
                        </div>
                        <div className="product_item_line delete-item col-md-1 col-sm-1 col-xs-3">
                          <span
                            // href={`/profile/${e?.requestUser?.profile?.sid}`}
                            title="View Details"
                            onClick={() =>
                              handleViewClick(
                                e.sid,
                                e?.requestUser?.profile?.sid
                              )
                            }
                          >
                            <i className="ti ti-eye"></i>
                          </span>
                        </div>
                        <button onClick={() => handleReuest(e.sid)}>
                          Accept
                        </button>
                      </div>
                    );
                  })}
                  {/* <div className="row">
                    <div className="product_item_line name-item col-md-5 col-sm-5 col-xs-12">
                      <a href="#" className="gla_product_thumbnail">
                        <img alt="" src="http://placehold.it/100x100" />
                      </a>
                      <div className="product-info">
                        <a href="#">Test Female 2</a>
                        <p>20 yrs / 5.5ft</p>
                      </div>
                    </div>
                    <div className="product_item_line price-item col-md-2 col-sm-2 col-xs-3">
                      <span className="cart-price">Muslim</span>
                    </div>
                    <div className="product_item_line qty-item col-md-2 col-sm-2 col-xs-3">
                      <div className="add-to-cart">Pune</div>
                    </div>
                    <div className="product_item_line price-item col-md-2 col-sm-2 col-xs-3">
                      <span className="cart-price">12-08-2002</span>
                    </div>
                    <div className="product_item_line delete-item col-md-1 col-sm-1 col-xs-3">
                      <a href="/profile" title="View Details">
                        <i className="ti ti-eye"></i>
                      </a>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </section>
        </section>

        <Footer />
      </div>
    </>
  );
}

export default Intrest;
