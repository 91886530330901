import "./AccountSettings.css";
import Header from "../../Components/Shared/Header/Header";
import Footer from "../../Components/Shared/Footer/Footer";
import { useForm } from "react-hook-form";
import VivahApi from "../../api";

function AccountSettings() {
  const { register, handleSubmit } = useForm();

  const onSubmit = async (reasaon) => {
    try {
      const resp = await VivahApi.profile.deactivateProfile(reasaon);
      alert("deactivated");
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div classNameName="gla_page" id="gla_page">
        <a href="#gla_page" classNameName="gla_top ti ti-angle-up gla_go"></a>
        <Header />
        <div
          className="gla_page_title gla_image_bck gla_wht_txt"
          data-color="#282828"
        >
          <div className="container text-left">
            <div className="row">
              <div className="col-md-8">
                <h1 className="gla_h1_title">Account Settings</h1>
              </div>
              <div className="col-md-4">
                <div className="breadcrumbs">
                  <a href="#">Home</a>
                  <span>Account Settings</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section id="gla_content" className="gla_content">
          <section className="gla_section">
            <div className="container">
              <h3>Deactivate/Delete Account</h3>
              <h6>
                Once you deactivate or delete the account, you will not be able
                to login again.
              </h6>
              <div className="row">
                <div className="col-md-12">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                      <div className="col-md-12">
                        <input
                          type="radio"
                          {...register("deActivateReason")}
                          name="deActivateReason"
                          value="Found my match on vivahbandh"
                        />
                        <span>Found my match on vivahbandh</span>
                        <br />
                        <input
                          type="radio"
                          {...register("deActivateReason")}
                          name="deActivateReason"
                          value="Found my match on other website"
                        />
                        <span>Found my match on other website</span>
                        <br />
                        <input
                          type="radio"
                          {...register("deActivateReason")}
                          name="deActivateReason"
                          value="Found my match other than website"
                        />
                        <span>Found my match other than website</span>
                        <br />
                        <input
                          type="radio"
                          {...register("deActivateReason")}
                          name="deActivateReason"
                          value="Not intrested"
                        />
                        <span>Not intrested</span>
                        <br />
                      </div>

                      <div className="col-md-12 mt-2">
                        <input
                          type="submit"
                          className="btn submit"
                          value="Send"
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </section>

        <Footer />
      </div>
    </>
  );
}

export default AccountSettings;
