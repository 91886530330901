import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "react-chat-elements/dist/main.css";
import "./ChattingPage.css";
import { useLocation } from "react-router-dom";
import VivahApi from "../../api";
// Chat Component
import { ChatList } from "react-chat-elements";
import { MessageList } from "react-chat-elements";
import { Input } from "react-chat-elements";
import Header from "../../Components/Shared/Header/Header";
import Footer from "../../Components/Shared/Footer/Footer";
function ChattingPage() {
  const location = useLocation();
  const userData = location.state?.data;
  //   console.log(userData.);
  const [toggle, setToggle] = useState(false);
  const [showChat, setChat] = useState(false);
  const [chatList, setChatList] = useState([]);
  const [msgData, setMsgData] = useState([]);
  const [selectedUser, setSelectedUser] = useState(-1);

  const refreshPage = () => {
    setTimeout(() => {
      window.location.reload(false);
    }, 0);
  };

  const handleToggle = () => {
    setToggle(!toggle);
  };

  const getMsgList = async () => {
    const chatLists = await VivahApi.Chat.getChatList();
    console.log(chatLists, "steph chatlist");
    setChatList(chatLists.data);

    return chatLists.data;
  };

  const chatUser = (obj) => {
    // console.log("Chat Obje", obj);
    setChat(true);
    setSelectedUser(obj);
  };

  const backToChat = () => {
    setChat(false);
  };

  useEffect(() => {
    const init = async () => {
      const data = await getMsgList(); //chng
      // console.log("DATA", data);
      setSelectedUser(data[0]);
    };

    init();
  }, []);

  useEffect(() => {
    // console.log("Selected User", selectedUser);
    const fetchText = async () => {
      if (selectedUser === -1) return;
      const msgData = await VivahApi.Chat.getMsgByUser({
        id: selectedUser?.userId,
        name: selectedUser?.title,
      });
      // console.log(msgData.data);

      setMsgData(msgData.data);
      getMsgList();
    };
    fetchText();
  }, [selectedUser]);

  return (
    <>
      {/* <Header /> */}
      <div className="chatting_page">
        <div className="chat_heading">
          <div>{userData?.firstName}</div>
        </div>
        <div className="chat_body">
          <div className="user_list">
            <ChatList
              className="chat-list"
              dataSource={chatList}
              // {[
              //   {
              //     avatar:
              //       "https://avatars.githubusercontent.com/u/80540635?v=4",
              //     alt: "kursat_avatar",
              //     title: "Kursat",
              //     subtitle:
              //       "Why don't we go to the No Way Home movie this weekend ?",
              //     date: new Date(),
              //     unread: 3,
              //   },
              //   {
              //     avatar:
              //       "https://avatars.githubusercontent.com/u/80540635?v=4",
              //     alt: "kursat_avatar",
              //     title: "John",
              //     subtitle:
              //       "Why don't we go to the No Way Home movie this weekend ?",
              //     date: new Date(),
              //     unread: 2,
              //   },
              //   {
              //     avatar:
              //       "https://avatars.githubusercontent.com/u/80540635?v=4",
              //     alt: "kursat_avatar",
              //     title: "Mickel",
              //     subtitle:
              //       "Why don't we go to the No Way Home movie this weekend ?",
              //     date: new Date(),
              //     unread: 1,
              //   },
              //   {
              //     avatar:
              //       "https://avatars.githubusercontent.com/u/80540635?v=4",
              //     alt: "kursat_avatar",
              //     title: "Jay",
              //     subtitle:
              //       "Why don't we go to the No Way Home movie this weekend ?",
              //     date: new Date(),
              //     unread: 3,
              //   },
              // ]}
              onClick={chatUser}
            />
          </div>
          <div className="chat">
            <MessageList
              className="message-list"
              lockable={true}
              toBottomHeight={"100%"}
              dataSource={msgData}
            />
            <footer className="chat_footer">
              <input type="text" placeholder="Type message here..." />
              <button style={{ marginRight: "5px" }}>Send</button>
              <button onClick={backToChat}>Back</button>
            </footer>
          </div>
          {/* <Footer /> */}
        </div>
      </div>
    </>
  );
}

export default ChattingPage;
