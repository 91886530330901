export function calculateAgeInYearsAndMonths(birthDate) {
  var today = new Date();
  var yearsDiff = today.getFullYear() - birthDate.getFullYear();
  var monthsDiff = today.getMonth() - birthDate.getMonth();
  if (monthsDiff < 0) {
    yearsDiff--;
    monthsDiff += 12;
  }
  return yearsDiff + " years, " + monthsDiff + " months";
}

export function calculateAge(birthDate) {
  var today = new Date();
  var yearsDiff = today.getFullYear() - birthDate.getFullYear();

  return yearsDiff;
}
