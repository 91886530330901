import "./UpdateProfile.css";
import Header from "../../Components/Shared/Header/Header";
import Footer from "../../Components/Shared/Footer/Footer";
import { useForm, Controller } from "react-hook-form";
import axios from "axios";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import Select from "react-select";
import VivahApi from "../../api";
import Profile from "../../api/Profile";
import moment from "moment";
import { getUniqueRes } from "../../utils/api-utils";

function UpdateProfile() {
  const { register, handleSubmit, formState, control, setValue, watch, reset } =
    useForm();

  const watchedValue = watch();

  const genders = [
    { value: "M", label: "Male" },
    { value: "F", label: "Female" },
    { value: "T", label: "Transgender" },
  ];

  // const options = [];
  const [profile, setProfile] = useState({});
  const [casteId, setCasteID] = useState([]);
  const [gothraId, setGothraID] = useState([]);
  const [cityId, setCityID] = useState([]);
  const [step, setStep] = useState(1);

  useEffect(() => {
    const init = async () => {
      const res = await VivahApi.profile.listProfile();
      // const res = await axios.get('http://vivahbandhapi-env.eba-fdn2hwky.ap-south-1.elasticbeanstalk.com/religion-masters?filter[include][]=castes')
      // console.log("resp", res);
      setProfile(res.data);
      const userProfileData = await VivahApi.Auth.getUserProfileUpdateData();
      // console.log("userProfileData", userProfileData);
      const { user, ...prof } = userProfileData.data;
      // console.log("User", user, "profile", prof);
      user.dateOfBirth = moment(user.dateOfBirth).format("YYYY-MM-DD");
      reset({ profile: prof, user: user });
    };
    init();
  }, []);

  // console.log('watchedValue', watchedValue);

  const nextStep = () => {
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  const showGothra = () => {
    const HinduId =
      profile.religions && profile.religions.find((e) => e.name == "Hindu");
    // console.log(HinduId);
    // console.log(watchedValue["religionId"]);
    return (
      HinduId &&
      watchedValue["profile"] &&
      HinduId.sid == watchedValue["profile"]["religionId"]
    );
  };

  const onSubmit = async (data) => {
    // console.log("data", data);
    if (!showGothra()) {
      data.profile.gothraId = 0;

      // console.log(data.profile.gothraId);
    }
    let resprofile = getUniqueRes(data.profile);
    // console.log("notnull", resprofile);
    const profileData = VivahApi.Auth.updateProfile(
      resprofile,
      data.profile.sid
    );
    alert("success");
  };

  const getSelectedReligion = () => {
    return (
      profile.religions &&
      watchedValue["profile"] &&
      profile.religions.find(
        (e) => e.sid === watchedValue["profile"]["religionId"]
      )
    );
  };

  const getSelectedState = () => {
    return (
      profile.states &&
      watchedValue["profile"] &&
      profile.states.find((e) => e.sid === watchedValue["profile"]["stateId"])
    );
  };

  const getSelecteFamilyState = () => {
    return (
      profile.states &&
      watchedValue["profile"] &&
      profile.states.find(
        (e) => e.sid === watchedValue["profile"]["familyStateId"]
      )
    );
  };
  return (
    <>
      <div className="gla_page" id="gla_page">
        <a href="#gla_page" className="gla_top ti ti-angle-up gla_go"></a>
        <Header />
        <div
          className="gla_page_title gla_image_bck gla_wht_txt"
          data-color="#282828"
        >
          <div className="container text-left">
            <div className="row">
              <div className="col-md-8">
                <h1 className="gla_h1_title">Update Profile</h1>
              </div>
              <div className="col-md-4">
                <div className="breadcrumbs">
                  <a href="#">Home</a>
                  <span>Update Profile</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section id="gla_content" className="gla_content">
          <section className="gla_section">
            <div className="container">
              <h3>Update Profile</h3>
              <div className="row">
                <div className="col-md-12  col-xs-12">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    {step === 1 && (
                      <>
                        <h3 id="basic">Basic Information</h3>
                        <div className="row">
                          <div className="col-md-6">
                            <label>Your First Name*</label>
                            <input
                              type="text"
                              disabled
                              {...register("user.firstName")}
                              name="firstName"
                              className={`form-control form-opacity border-radius-4`}
                            />
                          </div>
                          <div className="col-md-6">
                            <label>Your Last Name*</label>
                            <input
                              type="text"
                              disabled
                              {...register("user.lastName")}
                              name="lastName"
                              className={`form-control form-opacity border-radius-4`}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <label>Date of Birth*</label>
                            <input
                              type="date"
                              disabled
                              {...register("user.dateOfBirth")}
                              name="dateOfBirth"
                              onChange={(e) => console.log(e.target.value)}
                              className={`form-control form-opacity border-radius-4`}
                            />
                          </div>
                          <div className="col-md-6">
                            {/* <label>Gender*</label>
                                                <Select options={gender}
                                                    value={(gender) && gender.find(e => e.value === watchedValue["gender"])}
                                                /> */}
                            <label>Gender*</label>
                            <Controller
                              control={control}
                              name="gender"
                              render={({ onChange, value, name, ref }) => (
                                <Select
                                  inputRef={ref}
                                  options={genders}
                                  getOptionLabel={(e) => e.label}
                                  getOptionValue={(e) => e.value}
                                  value={
                                    watchedValue["profile"] &&
                                    genders.find(
                                      (e) =>
                                        e.value ===
                                        watchedValue["profile"]["gender"]
                                    )
                                  }
                                  onChange={(val) => {
                                    //console.log(val);
                                    setValue("profile.gender", val.value);
                                  }}
                                />
                              )}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <label>Your e-mail*</label>
                            <input
                              disabled
                              type="text"
                              {...register("user.email")}
                              name="email"
                              className={`form-control form-opacity border-radius-4`}
                            />
                          </div>
                          <div className="col-md-6">
                            <label>Your Mobile*</label>
                            <input
                              type="text"
                              disabled
                              name="phone"
                              {...register("user.phone")}
                              className={`form-control form-opacity border-radius-4`}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <label>Religion*</label>
                            <Controller
                              control={control}
                              name="religionId"
                              render={({ onChange, value, name, ref }) => (
                                <Select
                                  inputRef={ref}
                                  options={profile.religions}
                                  getOptionLabel={(e) => e.name}
                                  getOptionValue={(e) => e.sid}
                                  value={getSelectedReligion()}
                                  onChange={(val) => {
                                    // console.log(val);
                                    setValue("profile.religionId", val.sid);
                                    setValue("profile.casteId", -1);
                                    // console.log('caste', val.castes)
                                    setCasteID(val.castes);
                                  }}
                                />
                              )}
                            />
                          </div>
                          <div className="col-md-6">
                            <label>Caste*</label>
                            <Controller
                              control={control}
                              name="casteId"
                              render={({ onChange, value, name, ref }) => (
                                <Select
                                  inputRef={ref}
                                  options={
                                    getSelectedReligion() &&
                                    getSelectedReligion().castes
                                  }
                                  getOptionLabel={(e) => e.name}
                                  getOptionValue={(e) => e.sid}
                                  value={
                                    watchedValue["profile"] &&
                                    getSelectedReligion() &&
                                    getSelectedReligion().castes.find(
                                      (e) =>
                                        e.sid ===
                                        watchedValue["profile"]["casteId"]
                                    )
                                  }
                                  onChange={(val) => {
                                    // console.log(val);
                                    setValue("profile.casteId", val.sid);
                                  }}
                                />
                              )}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <label>Mother Tongue*</label>
                            <Controller
                              control={control}
                              name="motherTongueId"
                              render={({ onChange, value, name, ref }) => (
                                <Select
                                  inputRef={ref}
                                  options={profile.motherTongues}
                                  getOptionLabel={(e) => e.name}
                                  getOptionValue={(e) => e.sid}
                                  value={
                                    profile.motherTongues &&
                                    watchedValue["profile"] &&
                                    profile.motherTongues.find(
                                      (e) =>
                                        e.sid ===
                                        watchedValue["profile"][
                                          "motherTongueId"
                                        ]
                                    )
                                  }
                                  onChange={(val) => {
                                    //console.log(val);
                                    setValue("profile.motherTongueId", val.sid);
                                  }}
                                />
                              )}
                            />
                          </div>
                          {showGothra() && (
                            <div className="col-md-6">
                              <label>Gothra/Gothram*</label>
                              <Controller
                                control={control}
                                name="gothraId"
                                render={({ onChange, value, name, ref }) => (
                                  <Select
                                    inputRef={ref}
                                    options={profile.gothra}
                                    getOptionLabel={(e) => e.name}
                                    getOptionValue={(e) => e.sid}
                                    value={
                                      profile.gothra &&
                                      watchedValue["profile"] &&
                                      profile.gothra.find(
                                        (e) =>
                                          e.sid ===
                                          watchedValue["profile"]["gothraId"]
                                      )
                                    }
                                    onChange={(val) => {
                                      setValue("profile.gothraId", val.sid);
                                    }}
                                  />
                                )}
                              />
                            </div>
                          )}
                        </div>
                      </>
                    )}
                    {step === 2 && (
                      <>
                        <h3 id="family">Family Information</h3>
                        <div className="row">
                          <div className="col-md-6">
                            <label>State You Live In*</label>
                            <Controller
                              control={control}
                              name="stateId"
                              render={({ onChange, value, name, ref }) => (
                                <Select
                                  inputRef={ref}
                                  options={profile.states}
                                  getOptionLabel={(e) => e.name}
                                  getOptionValue={(e) => e.sid}
                                  value={getSelectedState()}
                                  onChange={(val) => {
                                    //console.log(val);
                                    setValue("profile.stateId", val.sid);
                                    setCityID(val.cities);
                                  }}
                                />
                              )}
                            />
                          </div>

                          <div className="col-md-6">
                            <label>City You Live In*</label>
                            <Controller
                              control={control}
                              name="cityId"
                              render={({ onChange, value, name, ref }) => (
                                <Select
                                  inputRef={ref}
                                  options={
                                    getSelectedState() &&
                                    getSelectedState().cities
                                  }
                                  getOptionLabel={(e) => e.name}
                                  getOptionValue={(e) => e.sid}
                                  value={
                                    watchedValue["profile"] &&
                                    getSelectedState() &&
                                    getSelectedState().cities.find(
                                      (e) =>
                                        e.sid ===
                                        watchedValue["profile"]["cityId"]
                                    )
                                  }
                                  onChange={(val) => {
                                    setValue("profile.cityId", val.sid);
                                  }}
                                />
                              )}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <label>Full Address*</label>
                            <textarea
                              name="address"
                              {...register("profile.address")}
                              className="form-control form-opacity border-radius-4"
                            ></textarea>
                          </div>
                          <div className="col-md-6">
                            <label>Height*</label>
                            <Controller
                              control={control}
                              name="heightId"
                              render={({ onChange, value, name, ref }) => (
                                <Select
                                  inputRef={ref}
                                  options={profile.height}
                                  getOptionLabel={(e) => e.height}
                                  getOptionValue={(e) => e.sid}
                                  value={
                                    profile.height &&
                                    watchedValue["profile"] &&
                                    profile.height.find(
                                      (e) =>
                                        e.sid ===
                                        watchedValue["profile"]["heightId"]
                                    )
                                  }
                                  onChange={(val) => {
                                    setValue("profile.heightId", val.sid);
                                  }}
                                />
                              )}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <label>You Live With Your Family*</label>
                            <input
                              type="radio"
                              {...register("profile.withFamily")}
                              value="no"
                            />
                            <span>No</span>
                            <input
                              type="radio"
                              {...register("profile.withFamily")}
                              value="yes"
                            />
                            <span>Yes</span>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <label>State You Family Live In*</label>
                            <Controller
                              control={control}
                              name="familyStateId"
                              render={({ onChange, value, name, ref }) => (
                                <Select
                                  inputRef={ref}
                                  options={profile.states}
                                  getOptionLabel={(e) => e.name}
                                  getOptionValue={(e) => e.sid}
                                  value={getSelecteFamilyState()}
                                  onChange={(val) => {
                                    //console.log(val);
                                    setValue("profile.familyStateId", val.sid);
                                    setCityID(val.cities);
                                  }}
                                />
                              )}
                            />
                          </div>

                          <div className="col-md-6">
                            <label>City Your Family Live In *</label>
                            <Controller
                              control={control}
                              name="familyCityId"
                              render={({ onChange, value, name, ref }) => (
                                <Select
                                  inputRef={ref}
                                  options={
                                    getSelecteFamilyState() &&
                                    getSelecteFamilyState().cities
                                  }
                                  getOptionLabel={(e) => e.name}
                                  getOptionValue={(e) => e.sid}
                                  value={
                                    watchedValue["profile"] &&
                                    getSelecteFamilyState() &&
                                    getSelecteFamilyState().cities.find(
                                      (e) =>
                                        e.sid ===
                                        watchedValue["profile"]["familyCityId"]
                                    )
                                  }
                                  onChange={(val) => {
                                    setValue("profile.familyCityId", val.sid);
                                  }}
                                />
                              )}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <label>Marital Status *</label>
                            <Controller
                              control={control}
                              name="maritalStatusId"
                              render={({ onChange, value, name, ref }) => (
                                <Select
                                  inputRef={ref}
                                  options={profile.maritalStatus}
                                  getOptionLabel={(e) => e.maritalStatus}
                                  getOptionValue={(e) => e.sid}
                                  value={
                                    profile.maritalStatus &&
                                    watchedValue["profile"] &&
                                    profile.maritalStatus.find(
                                      (e) =>
                                        e.sid ===
                                        watchedValue["profile"][
                                          "maritalStatusId"
                                        ]
                                    )
                                  }
                                  onChange={(val) => {
                                    setValue(
                                      "profile.maritalStatusId",
                                      val.sid
                                    );
                                  }}
                                />
                              )}
                            />
                            {/* <input type="radio" {...register('profile.maritalStatus')} value="Never Married" />
                                                        <span>Never Married</span>
                                                        <input type="radio" {...register('profile.maritalStatus')} value="Widow or Widower" />
                                                        <span>Widow or Widower</span>
                                                        <input type="radio" {...register('profile.maritalStatus')} value="Divorced" />
                                                        <span>Divorced</span>
                                                        <input type="radio" {...register('profile.maritalStatus')} value="Awaiting Divorce" />
                                                        <span>Awaiting Divorce</span> */}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <label>Your Diet*</label>
                            <input
                              type="radio"
                              {...register("profile.diet")}
                              value="Veg"
                            />
                            <span>Veg</span>
                            <input
                              type="radio"
                              {...register("profile.diet")}
                              value="Non Veg"
                            />
                            <span>Non Veg</span>
                            <input
                              type="radio"
                              {...register("profile.diet")}
                              value="Occasionally Non Veg"
                            />
                            <span>Occasionally Non Veg</span>
                            <input
                              type="radio"
                              {...register("profile.diet")}
                              value="Eggetarian"
                            />
                            <span>Eggetarian</span>
                            <input
                              type="radio"
                              {...register("profile.diet")}
                              value="Jain"
                            />
                            <span>Jain</span>
                          </div>
                        </div>
                      </>
                    )}
                    {step === 3 && (
                      <>
                        <h3 id="education">Education & Career</h3>
                        <div className="row">
                          <div className="col-md-6">
                            <label>Yor highest qualification *</label>
                            <Controller
                              control={control}
                              name="qualificationId"
                              render={({ onChange, value, name, ref }) => (
                                <Select
                                  inputRef={ref}
                                  options={profile.qualification}
                                  getOptionLabel={(e) => e.name}
                                  getOptionValue={(e) => e.sid}
                                  value={
                                    profile.qualification &&
                                    watchedValue["profile"] &&
                                    profile.qualification.find(
                                      (e) =>
                                        e.sid ===
                                        watchedValue["profile"][
                                          "qualificationId"
                                        ]
                                    )
                                  }
                                  onChange={(val) => {
                                    setValue(
                                      "profile.qualificationId",
                                      val.sid
                                    );
                                  }}
                                />
                              )}
                            />
                          </div>

                          <div className="col-md-6">
                            <label>Your College Name(Highest Degree)*</label>
                            <input
                              type="text"
                              {...register("profile.collegeName")}
                              className={`form-control form-opacity border-radius-4 `}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <label>You Work With *</label>
                            <Controller
                              control={control}
                              name="professionTypeId"
                              render={({ onChange, value, name, ref }) => (
                                <Select
                                  inputRef={ref}
                                  options={profile.professionType}
                                  getOptionLabel={(e) => e.name}
                                  getOptionValue={(e) => e.sid}
                                  value={
                                    profile.professionType &&
                                    watchedValue["profile"] &&
                                    profile.professionType.find(
                                      (e) =>
                                        e.sid ===
                                        watchedValue["profile"][
                                          "professionTypeId"
                                        ]
                                    )
                                  }
                                  onChange={(val) => {
                                    setValue(
                                      "profile.professionTypeId",
                                      val.sid
                                    );
                                  }}
                                />
                              )}
                            />
                          </div>

                          <div className="col-md-6">
                            <label>Working As *</label>
                            <Controller
                              control={control}
                              name="professionId"
                              render={({ onChange, value, name, ref }) => (
                                <Select
                                  inputRef={ref}
                                  options={profile.profession}
                                  getOptionLabel={(e) => e.name}
                                  getOptionValue={(e) => e.sid}
                                  value={
                                    profile.profession &&
                                    watchedValue["profile"] &&
                                    profile.profession.find(
                                      (e) =>
                                        e.sid ===
                                        watchedValue["profile"]["professionId"]
                                    )
                                  }
                                  onChange={(val) => {
                                    setValue("profile.professionId", val.sid);
                                  }}
                                />
                              )}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <label>Your Company Name (Current)*</label>
                            <input
                              type="text"
                              {...register("profile.companyName")}
                              className={`form-control form-opacity border-radius-4`}
                            />
                          </div>

                          <div className="col-md-6">
                            <label>Income (Yearly) *</label>
                            <Controller
                              control={control}
                              name="incomeId"
                              render={({ onChange, value, name, ref }) => (
                                <Select
                                  inputRef={ref}
                                  options={profile.income}
                                  getOptionLabel={(e) => e.name}
                                  getOptionValue={(e) => e.sid}
                                  value={
                                    profile.income &&
                                    watchedValue["profile"] &&
                                    profile.income.find(
                                      (e) =>
                                        e.sid ===
                                        watchedValue["profile"]["incomeId"]
                                    )
                                  }
                                  onChange={(val) => {
                                    setValue("profile.incomeId", val.sid);
                                  }}
                                />
                              )}
                            />
                          </div>
                        </div>
                      </>
                    )}
                    {step === 4 && (
                      <>
                        <h3 id="about">About You</h3>
                        <div className="row">
                          <div className="col-md-12">
                            <label>About Yourself *</label>
                            <textarea
                              name="about_yourself"
                              {...register("profile.aboutYourself")}
                              className="form-control form-opacity border-radius-4"
                            ></textarea>
                          </div>
                          <div className="col-md-6">
                            <label>Body Weight</label>
                            <input
                              type="text"
                              {...register("profile.bodyWeight")}
                              className={`form-control form-opacity border-radius-4`}
                            />
                          </div>
                          <div className="col-md-6">
                            <label>Body Type</label>
                            <Controller
                              control={control}
                              name="bodyTypeId"
                              render={({ onChange, value, name, ref }) => (
                                <Select
                                  inputRef={ref}
                                  options={profile.BodyType}
                                  getOptionLabel={(e) => e.name}
                                  getOptionValue={(e) => e.sid}
                                  value={
                                    profile.BodyType &&
                                    watchedValue["profile"] &&
                                    profile.BodyType.find(
                                      (e) =>
                                        e.sid ===
                                        watchedValue["profile"]["bodyTypeId"]
                                    )
                                  }
                                  onChange={(val) => {
                                    setValue("profile.bodyTypeId", val.sid);
                                  }}
                                />
                              )}
                            />
                          </div>
                          <div className="col-md-6">
                            <label>Health Information</label>
                            <Controller
                              control={control}
                              name="healthInformationId"
                              render={({ onChange, value, name, ref }) => (
                                <Select
                                  inputRef={ref}
                                  options={profile.HealthInfo}
                                  getOptionLabel={(e) => e.healthInfo}
                                  getOptionValue={(e) => e.sid}
                                  value={
                                    profile.HealthInfo &&
                                    watchedValue["profile"] &&
                                    profile.HealthInfo.find(
                                      (e) =>
                                        e.sid ===
                                        watchedValue["profile"][
                                          "healthInformationId"
                                        ]
                                    )
                                  }
                                  onChange={(val) => {
                                    setValue(
                                      "profile.healthInformationId",
                                      val.sid
                                    );
                                  }}
                                />
                              )}
                            />
                          </div>
                          <div className="col-md-6">
                            <label>Disability</label>
                            <Controller
                              control={control}
                              name="disabilityId"
                              render={({ onChange, value, name, ref }) => (
                                <Select
                                  inputRef={ref}
                                  options={profile.disability}
                                  getOptionLabel={(e) => e.disability}
                                  getOptionValue={(e) => e.sid}
                                  value={
                                    profile.disability &&
                                    watchedValue["profile"] &&
                                    profile.disability.find(
                                      (e) =>
                                        e.sid ===
                                        watchedValue["profile"]["disabilityId"]
                                    )
                                  }
                                  onChange={(val) => {
                                    setValue("profile.disabilityId", val.sid);
                                  }}
                                />
                              )}
                            />
                          </div>
                          <div className="col-md-6">
                            <label>Complexion</label>
                            <input
                              type="text"
                              {...register("profile.complexion")}
                              className={`form-control form-opacity border-radius-4`}
                            />
                          </div>
                          <div className="col-md-6">
                            <label>Blood Group</label>
                            <Controller
                              control={control}
                              name="bloodGroupId"
                              render={({ onChange, value, name, ref }) => (
                                <Select
                                  inputRef={ref}
                                  options={profile.bloodGroup}
                                  getOptionLabel={(e) => e.bloodGroupName}
                                  getOptionValue={(e) => e.sid}
                                  value={
                                    profile.bloodGroup &&
                                    watchedValue["profile"] &&
                                    profile.bloodGroup.find(
                                      (e) =>
                                        e.sid ===
                                        watchedValue["profile"]["bloodGroupId"]
                                    )
                                  }
                                  onChange={(val) => {
                                    setValue("profile.bloodGroupId", val.sid);
                                  }}
                                />
                              )}
                            />
                          </div>
                          <div className="col-md-6">
                            <label>Birth Place</label>
                            <input
                              type="text"
                              {...register("profile.birthPlace")}
                              className={`form-control form-opacity border-radius-4`}
                            />
                          </div>
                          <div className="col-md-6">
                            <label>Birth Time</label>
                            <input
                              type="text"
                              {...register("profile.birthTime")}
                              className={`form-control form-opacity border-radius-4`}
                            />
                          </div>
                          <div className="col-md-6">
                            <label>Father Name </label>
                            <input
                              type="text"
                              {...register("profile.fatherName")}
                              className={`form-control form-opacity border-radius-4`}
                            />
                          </div>
                          <div className="col-md-6">
                            <label>Mother Name </label>
                            <input
                              type="text"
                              {...register("profile.motherName")}
                              className={`form-control form-opacity border-radius-4`}
                            />
                          </div>
                          <div className="col-md-6">
                            <label>No of Brother</label>
                            <input
                              type="text"
                              {...register("profile.brother")}
                              className={`form-control form-opacity border-radius-4`}
                            />
                          </div>
                          {/* <div className="col-md-6">
                            <label>Family Type</label>
                            <input
                              type="text"
                              {...register("profile.brother")}
                              className={`form-control form-opacity border-radius-4`}
                            />
                          </div> */}
                          {/* <div className="col-md-6">
                            <label>Family Values</label>
                            <input
                              type="text"
                              {...register("profile.brother")}
                              className={`form-control form-opacity border-radius-4`}
                            />
                          </div> */}

                          <div className="col-md-6">
                            <label>Family status</label>
                            <Controller
                              control={control}
                              name="familyStatusId"
                              render={({ onChange, value, name, ref }) => (
                                <Select
                                  inputRef={ref}
                                  options={profile.familyStatus}
                                  getOptionLabel={(e) => e.name}
                                  getOptionValue={(e) => e.sid}
                                  value={
                                    profile.familyStatus &&
                                    watchedValue["profile"] &&
                                    profile.familyStatus.find(
                                      (e) =>
                                        e.sid ===
                                        watchedValue["profile"][
                                          "familyStatusId"
                                        ]
                                    )
                                  }
                                  onChange={(val) => {
                                    setValue("profile.familyStatusId", val.sid);
                                  }}
                                />
                              )}
                            />
                          </div>
                          <div className="col-md-6">
                            <label>Family Values</label>
                            <Controller
                              control={control}
                              name="familyValuesId"
                              render={({ onChange, value, name, ref }) => (
                                <Select
                                  inputRef={ref}
                                  options={profile.familyValues}
                                  getOptionLabel={(e) => e.name}
                                  getOptionValue={(e) => e.sid}
                                  value={
                                    profile.familyValues &&
                                    watchedValue["profile"] &&
                                    profile.familyValues.find(
                                      (e) =>
                                        e.sid ===
                                        watchedValue["profile"][
                                          "familyValuesId"
                                        ]
                                    )
                                  }
                                  onChange={(val) => {
                                    setValue("profile.familyValuesId", val.sid);
                                  }}
                                />
                              )}
                            />
                          </div>
                          <div className="col-md-6">
                            <label>No of Sisters</label>
                            <input
                              type="text"
                              {...register("profile.sister")}
                              className={`form-control form-opacity border-radius-4`}
                            />
                          </div>
                          <div className="col-md-6">
                            <label>Father's Occupation</label>
                            <Controller
                              control={control}
                              name="professionId"
                              render={({ onChange, value, name, ref }) => (
                                <Select
                                  inputRef={ref}
                                  options={profile.profession}
                                  getOptionLabel={(e) => e.name}
                                  getOptionValue={(e) => e.sid}
                                  value={
                                    profile.profession &&
                                    watchedValue["profile"] &&
                                    profile.profession.find(
                                      (e) =>
                                        e.sid ===
                                        watchedValue["profile"][
                                          "fatherOccupationId"
                                        ]
                                    )
                                  }
                                  onChange={(val) => {
                                    setValue(
                                      "profile.fatherOccupationId",
                                      val.sid
                                    );
                                  }}
                                />
                              )}
                            />
                          </div>
                          <div className="col-md-6">
                            <label>Mother's Occupation</label>
                            <Controller
                              control={control}
                              name="professionId"
                              render={({ onChange, value, name, ref }) => (
                                <Select
                                  inputRef={ref}
                                  options={profile.profession}
                                  getOptionLabel={(e) => e.name}
                                  getOptionValue={(e) => e.sid}
                                  value={
                                    profile.profession &&
                                    watchedValue["profile"] &&
                                    profile.profession.find(
                                      (e) =>
                                        e.sid ===
                                        watchedValue["profile"][
                                          "motherOccupationId"
                                        ]
                                    )
                                  }
                                  onChange={(val) => {
                                    setValue(
                                      "profile.motherOccupationId",
                                      val.sid
                                    );
                                  }}
                                />
                              )}
                            />
                          </div>
                          {/* <div className="col-md-6">
                            <label>Native Place</label>
                            <Controller
                              control={control}
                              name="nativePlaceId"
                              render={({ onChange, value, name, ref }) => (
                                <Select
                                  inputRef={ref}
                                  options={
                                    // getSelectedState() &&
                                    profile.states?.cities
                                  }
                                  getOptionLabel={(e) => e.name}
                                  getOptionValue={(e) => e.sid}
                                  value={
                                    watchedValue["profile"] &&
                                    // getSelectedState() &&
                                    profile.states?.cities.find(
                                      (e) =>
                                        e.sid ===
                                        watchedValue["profile"]["nativePlaceId"]
                                    )
                                  }
                                  onChange={(val) => {
                                    setValue("profile.nativePlaceId", val.sid);
                                  }}
                                />
                              )}
                            />
                          </div> */}
                        </div>
                      </>
                    )}
                    <div className="row mt-2">
                      <div className="col-md-12 justify-content-between d-flex">
                        {step !== 1 && (
                          <button
                            type="button"
                            className="btn submit"
                            onClick={prevStep}
                          >
                            Previous
                          </button>
                        )}
                        {step !== 4 && (
                          <button
                            type="button"
                            className="btn submit"
                            onClick={nextStep}
                          >
                            Next
                          </button>
                        )}
                        {step === 4 && (
                          <input
                            type="submit"
                            className="btn submit"
                            value="Update"
                          />
                        )}
                      </div>
                    </div>
                  </form>
                </div>
                {/* <div className="col-md-3 col-md-pull-8 hidden-xs hidden-sm">
                                    <div className="widget">
                                        <h6 className="title">Categories</h6>
                                        <ul className="list-unstyled">
                                            <li><a href="#basic">Basic Information</a></li>
                                            <li><a href="#family">Family Information</a></li>
                                            <li><a href="#education">Education & Career</a></li>
                                            <li><a href="#about">About Yourself</a></li>
                                        </ul>
                                    </div>
                                </div> */}
              </div>
            </div>
          </section>
        </section>
        <Footer />
      </div>
    </>
  );
}

export default UpdateProfile;
