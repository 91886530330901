import createAxiosClient, { config } from "../utils/api-utils";
class AuthApi {
  constructor() {
    this.client = createAxiosClient();
  }
  async login(data) {
    return await this.client.post("/login", data);
  }
  async signUp(data) {
    return await this.client.post("/signup", data);
  }
  async userMe() {
    return await this.client.get("/users/me", config());
  }
  async signUpProfile(data) {
    return await this.client.post("/profile", data);
  }
  async updateProfile(data, id) {
    return await this.client.patch(`/profile/${id}`, data);
  }
  async getUserProfileData() {
    return await this.client.get("/users/profile", config());
  }

  async getUserProfileUpdateData() {
    return await this.client.get("/users/profile-update", config());
  }
}
export default AuthApi = new AuthApi();
