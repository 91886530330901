import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import VivahApi from "../../api";
import Plan from "../../api/Plan";

function PlanPurchase() {
  const [plan, setPlan] = useState();
  const navigate = useNavigate();
  const proceedCheckout = (plan) => {
    refreshPage();
    navigate("/checkout", { state: { data: plan } });
  };

  const refreshPage = () => {
    setTimeout(() => {
        window.location.reload(false);
    }, 0);
}

  useEffect(() => {
    const init = async () => {
      const plan = await VivahApi.Plan.listPlan();
      setPlan(plan.data);
    };
    init();
  }, []);

  return (
    <>
      <div className="gla_icon_boxes row text-left">
        {plan &&
          plan.map((e) => (
            <div className="col-md-3 col-sm-6">
              <a className="gla_news_block">
                <span className="gla_news_img">
                  <img src={e.img} />
                </span>
                <span className="gla_news_title">{e.planName}</span>
                <p>
                  <strong>{e.durationDescription}s</strong>
                  <br />
                  {e.description}
                </p>
                <div className="package-btn">
                  <button
                    type="button"
                    className="btn btn-default"
                    onClick={() => proceedCheckout(e)}
                  >
                    {e.amount}
                  </button>
                </div>
              </a>
            </div>
          ))}
      </div>
    </>
  );
}

export default PlanPurchase;
