import React, { useState } from "react";
import "./Login.css";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import axios from "axios";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { getMessaging, getToken } from "firebase/messaging";
import { messaging } from "../../firebase";
import VivahApi from "../../api";
function Login() {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const requestForToken = async () => {
    try {
      const currentToken = await getToken(messaging, {
        vapidKey: process.env.VAPID_KEY,
      });

      if (currentToken) {
        const result = await VivahApi.FirebaseToken.createToken(currentToken);
      } else {
        // Show permission request UI
        console.log(
          "No registration token available. Request permission to generate one."
        );
      }
    } catch (error) {
      console.log("An error occurred while retrieving token. ", error);
    }
  };

  const onSubmit = async (data) => {
    try {
      const res = await VivahApi.Auth.login(data);
      console.log(res, "response");
      window.localStorage.setItem("token", res.data.token);
      const details = await VivahApi.Auth.userMe();
      await requestForToken();

      console.log(details, "profile");
      window.localStorage.setItem(
        "usermame",
        details.data?.user?.firstName + " " + details.data?.user?.lastName
      );
      if (
        details.data.isPremium &&
        details.data.premiumPlanExpiry > new Date().toISOString().slice(0, 10)
      ) {
        window.localStorage.setItem("planName", details.data.plan.planName);
      }

      // window.location = "/";
    } catch (e) {
      console.log(e);
      alert("Invalid User Name Password");
    }
  };

  return (
    <>
      <div className="gla_page" id="gla_page">
        <a href="#gla_page" className="gla_top ti ti-angle-up gla_go"></a>
        <Header />
        <div
          className="gla_page_title gla_image_bck gla_wht_txt"
          data-color="#282828"
        >
          <div className="container text-left">
            <div className="row">
              <div className="col-md-8">
                <h1 className="gla_h1_title">Admin Login</h1>
              </div>
            </div>
          </div>
        </div>
        <section id="gla_content" className="gla_content">
          <section className="gla_section">
            <div className="container">
              <div className="row">
                <div className="col-md-12 col-xs-12 col-md-offset-3">
                  <div className="checkout">
                    <div className="checkout-row row">
                      <div className="log-in col-md-6">
                        <h3 className="title">Admin Login</h3>
                        <div className="box">
                          <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="row">
                              <div className="col-md-6">
                                <input
                                  type="text"
                                  name="email"
                                  {...register("email")}
                                  placeholder="Email / Username"
                                  className="input-text form-control"
                                />
                              </div>
                              <div className="col-md-6">
                                <input
                                  type="password"
                                  name="password"
                                  {...register("password")}
                                  placeholder="Password"
                                  className="input-text form-control"
                                />
                              </div>
                            </div>
                            <div className="row  sign-up-btn">
                              <div className="d-flex">
                                <button
                                  type="submit"
                                  title="Log in"
                                  className="button btn btn-default"
                                >
                                  <em className="fa-icon">
                                    <i className="fa fa-unlock"></i>
                                  </em>
                                  <span>Log in</span>
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>
        <Footer />
      </div>
    </>
  );
}

export default Login;
