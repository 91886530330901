import "./Checkout.css";
import Header from "../../Components/Shared/Header/Header";
import Footer from "../../Components/Shared/Footer/Footer";
import Payment from "../../../src/api/Payment";
import { useLocation } from "react-router-dom";

function Checkout() {
  const location = useLocation();
  const planData = location.state && location.state.data;
  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;

      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  async function displayRazorpay(Id) {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    // creating a new order
    const result = await Payment.createOrderId(Id);

    if (!result) {
      alert("Server error. Are you online?");
      return;
    }

    if (result.data.error) {
      alert(result.data.error.message);
      return;
    }

    // Getting the order details back
    const { orderId, name, email, phone, transactionId, planId } = result.data;

    const options = {
      key: process.env.REACT_APP_KEY_ID, // Enter the Key ID generated from the Dashboard
      name: name,
      description: "Test Transaction",
      //   image: { logo },
      order_id: orderId,
      handler: async function (response) {
        const data = {
          orderCreationId: orderId,
          razorpayPaymentId: response.razorpay_payment_id,
          razorpayOrderId: response.razorpay_order_id,
          razorpaySignature: response.razorpay_signature,
          transactionId: transactionId,
          planId: planId,
        };

        const result = await Payment.verifyPayment(data);

        if (result.data.error) {
          alert("Error" + result.data.error.message);
          return;
        }
        alert("success");
      },
      prefill: {
        name: name,
        email: email,
        contact: phone,
      },

      theme: {
        color: "#61dafb",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }

  return (
    <>
      <div className="gla_page" id="gla_page">
        <a href="#gla_page" className="gla_top ti ti-angle-up gla_go"></a>
        <Header />
        <div
          className="gla_page_title gla_image_bck gla_wht_txt"
          data-color="#282828"
        >
          <div className="container text-left">
            <div className="row">
              <div className="col-md-8">
                <h1 className="gla_h1_title">Checkout</h1>
              </div>
              <div className="col-md-4">
                <div className="breadcrumbs">
                  <a href="#">Home</a>
                  <span>Checkout</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section id="gla_content" className="gla_content">
          <section className="gla_section">
            <div className="container">
              <div className="row">
                <div className="col-md-12 col-xs-12">
                  <div className="checkout">
                    <div className="checkout-row row">
                      <div className="billing-address col-md-12">
                        <h3 className="title">
                          Purchase Plan: {planData.planName}
                        </h3>
                        {/* <div className="box">
                          <div className="row">
                            <div className="col-md-6">
                              <input
                                type="text"
                                readOnly
                                value=""
                                placeholder="First name"
                                className="input-text form-control"
                              />
                            </div>
                            <div className="col-md-6">
                              <input
                                type="text"
                                readOnly
                                value=""
                                placeholder="Last name"
                                className="input-text form-control"
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <input
                                type="text"
                                readOnly
                                value=""
                                placeholder="Email"
                                className="input-text form-control"
                              />
                            </div>
                            <div className="col-md-6">
                              <input
                                type="text"
                                readOnly
                                value=""
                                placeholder="Phone"
                                className="input-text form-control"
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <textarea
                                readOnly
                                value=""
                                placeholder="Address"
                                className="input-text form-control"
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <input
                                type="text"
                                readOnly
                                value=""
                                placeholder="City"
                                className="input-text form-control"
                              />
                            </div>
                            <div className="col-md-6">
                              <input
                                type="text"
                                readOnly
                                value=""
                                placeholder="State"
                                className="input-text form-control"
                              />
                            </div>
                          </div>
                          <div className="clearfix"></div>
                        </div> */}
                      </div>
                    </div>
                    <div className="checkout-row row">
                      <div className="cart-total col-md-6">
                        <h3 className="title">Cart Total</h3>
                        <div className="box">
                          <div className="cart-total-item">
                            <label>Cart subtotal</label>
                            <div className="price">{planData.amount}</div>
                          </div>
                          <div className="cart-total-item">
                            <label>Shipping fee</label>
                            <div className="price">0</div>
                          </div>
                          <div className="cart-total-item order-total">
                            <label>Order total price</label>
                            <div className="price">{planData.amount}</div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <h3 className="title">Payment Method</h3>
                        <div className="box">
                          <div className="payment-method">
                            <div className="payment-item">
                              <input
                                type="radio"
                                checked
                                className="radio"
                                name="payment-method"
                              />
                              <div className="method">
                                <p>Online</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <button
                      type="button"
                      title="Check out"
                      className="button btn-checkout btn btn-default"
                      onClick={() => displayRazorpay(planData.sid)}
                    >
                      <em className="fa-icon">
                        <i class="fa fa-arrow-right"></i>
                      </em>
                      <span>Check out</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>
        <Footer />
      </div>
    </>
  );
}

export default Checkout;
