import createAxiosClient, { config } from "../utils/api-utils";

class PaymentApi {
  constructor() {
    this.client = createAxiosClient();
  }
  async createOrderId(planId) {
    return await this.client.post("/create-order", { planId }, config());
  }

  async verifyPayment(data) {
    return await this.client.post("/payment-success", data, config());
  }
}
export default PaymentApi = new PaymentApi();
