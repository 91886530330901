import createAxiosClient, { config } from "../utils/api-utils";

class UploadApi {
  constructor() {
    this.client = createAxiosClient();
  }

  async uploadImg(formData) {
    const authHeader = config().headers;
    authHeader["Content-Type"] = "multipart/form-data";
    return await this.client.post("/upload-img", formData, {
      headers: authHeader,
    });
  }
}
export default UploadApi = new UploadApi();
