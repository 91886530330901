import createAxiosClient, { config } from "../utils/api-utils";
class InterestApi {
  constructor() {
    this.client = createAxiosClient();
  }
  async addInterest(data) {
    const reqData = {
      toId: data,
    };
    return await this.client.post("/interest", reqData, config());
  }

  async getRequest() {
    return await this.client.get("/interest-user", config());
  }

  async updateInterest(id, data) {
    return await this.client.patch(`/interest/${id}`, data);
  }
}
export default InterestApi = new InterestApi();
