import { Link } from "react-router-dom";
import "./Favourite.css";
import Header from "../../Components/Shared/Header/Header";
import Footer from "../../Components/Shared/Footer/Footer";
import axios from "axios";
import VivahApi from "../../api";
import { useEffect, useState } from "react";
import { calculateAgeInYearsAndMonths } from "../../utils/common";

function Favourite() {
  const [profiles, setProfiles] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [sortFilter, setSortFilter] = useState("sid DESC");
  const PAGE_COUNT = 5;

  useEffect(() => {
    const init = async () => {
      const res = await VivahApi.Favourite
        .listFavourite
        // sortFilter,
        // PAGE_COUNT,
        // (page - 1) * PAGE_COUNT
        ();
      console.log(res);
      setProfiles(res.data);
      // console.log(Math.ceil(res.data.count / PAGE_COUNT), "count");
      // setTotalPage(Math.ceil(res.data.count / PAGE_COUNT));

      // console.log(res, "searchProfile");
    };
    init();
  }, [page, sortFilter]);

  const selectedPageHandler = (selectedPage) => {
    setPage(selectedPage);
  };

  const handleSelectChange = (val) => {
    setSortFilter(val);
  };
  return (
    <>
      <div className="gla_page" id="gla_page">
        <a href="#gla_page" className="gla_top ti ti-angle-up gla_go"></a>
        <Header />
        <div
          className="gla_page_title gla_image_bck gla_wht_txt"
          data-color="#282828"
        >
          <div className="container text-left">
            <div className="row">
              <div className="col-md-8">
                <h1 className="gla_h1_title">Search Result</h1>
              </div>
              <div className="col-md-4">
                <div className="breadcrumbs">
                  <a href="#">Home</a>
                  <span>Search Result</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section id="gla_content" className="gla_content">
          <section className="gla_section">
            <div className="container">
              <div className="row">
                <div className="col-md-12 col-xs-12">
                  <div className="gla_shop_header">
                    <div className="row">
                      <div className="col-md-6">Showing 1-10 of 24 results</div>
                      <div className="col-md-6 text-right">
                        <Link to="/filter" className="mr-3">
                          Sort By
                        </Link>
                        <select
                          onChange={(e) => handleSelectChange(e.target.value)}
                          name="type"
                        >
                          {/* <option value="" selected="selected">
                            Sort By
                          </option> */}
                          <option value="id DESC">What's new</option>
                          <option value="age DESC">Age high to low</option>
                          <option value="age ASC">Age low to high</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    {profiles.map((p) => (
                      <div className="col-md-4 gla_anim_box">
                        <div className="gla_shop_item">
                          <span className="gla_shop_item_sale">Premium</span>
                          <span className="gla_shop_item_slider">
                            <img
                              src={p.photo || "assets/images/profile.png"}
                              alt=""
                            />
                          </span>

                          <a
                            href={`/profile/${p.profile.sid}`}
                            className="gla_shop_item_title"
                          >
                            <b>{`${p.profile?.user?.firstName} ${p.profile.user?.lastName}`}</b>
                            <i className="ti ti-calendar"></i>
                            {p.profile?.user?.lastName &&
                              calculateAgeInYearsAndMonths(
                                new Date(p.profile?.user?.dateOfBirth)
                              )}{" "}
                            <br />
                            <i className="ti ti-direction-alt"></i> Mother
                            Tongue is {p.profile?.motherTongue?.name} <br />
                            <i className="ti ti-shine"></i>{" "}
                            {p.profile?.religion?.name} <br />
                            <i className="ti ti-location-pin"></i> Lives in{" "}
                            {`${p.profile?.city?.state}, ${p.profile?.city?.nameOfCity}`}{" "}
                            <br />
                            <i className="ti ti-shield"></i> Studied{" "}
                            {p.profile?.qualification?.name} <br />
                            <i className="ti ti-medall"></i> Profession is{" "}
                            {p.profile?.profession?.name} <br />
                          </a>
                        </div>
                      </div>
                    ))}
                    {profiles.length === 0 && <p>No favourite match found.</p>}
                  </div>
                  <nav className="gla_blog_pag">
                    {/* <ul className="pagination">
                      <li>
                        <a href="#">
                          <i className="ti ti-angle-left"></i>
                        </a>
                      </li>
                      {[...Array(totalPage)].map((_, i) => {
                        return (
                          <button onClick={() => selectedPageHandler(i + 1)}>
                            {i + 1}
                          </button>
                        );
                      })}

                      <li>
                        <a href="#">
                          <i className="ti ti-angle-right"></i>
                        </a>
                      </li>
                    </ul> */}
                  </nav>
                </div>
              </div>
            </div>
          </section>
        </section>

        <Footer />
      </div>
    </>
  );
}

export default Favourite;
