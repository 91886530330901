import createAxiosClient, { config } from "../utils/api-utils";

class SearchApi {
  constructor() {
    this.client = createAxiosClient();
  }

  async getSearch(sortFilter, limit, skip) {
    let filter = JSON.stringify({
      order: sortFilter,
      skip,
      limit,
    });
    return await this.client.get(`/vvh-matches?filter=${filter}`, config());
  }

  async getSearchCount(query) {
    return await this.client.get(`/vvh-matches/count`, config());
  }
}
export default SearchApi = new SearchApi();
