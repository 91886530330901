import './About.css';
import Header from '../../Components/Shared/Header/Header';
import Footer from '../../Components/Shared/Footer/Footer';

function About() {
  return (
    <>
      <div classNameName="gla_page" id="gla_page">
        <a href="#gla_page" classNameName="gla_top ti ti-angle-up gla_go"></a>
        <Header />
        <div className="gla_page_title gla_image_bck gla_wht_txt" data-color="#282828">



          <div className="container text-left">
            <div className="row">

              <div className="col-md-8">
                <h1 className="gla_h1_title">About Us</h1>
              </div>

              <div className="col-md-4">
                <div className="breadcrumbs">
                  <a href="/">Home</a><span>About Us</span>
                </div>
              </div>

            </div>
          </div>
        </div>
        <section id="gla_content" className="gla_content">
          <section className="gla_section gla_image_bck" data-color="#fafafd">
            <div className="container text-center">
              <p><img src="images/animations/flower5.gif" data-bottom-top="@src:images/animations/flower5.gif; opacity:1" className="gla_animated_flower" height="150" alt="" /></p>
              <h2>Our Story</h2>
              <h3 className="gla_subtitle mt-2">VivahBandh is a matrimonial matchmaking service provider</h3>
              <p>Welcome to Vivahbandh.com! India's Most Trusted Matrimony service has helped many matches from across different communities. We are a Indian matrimonial matchmaking service provider. Our highly dedicated team is committed to provide 360 degree solutions to all prospective Indian brides and grooms. We are technology driven company providing the best platform to those who are genuinely looking for their soul mates. We have a long list of Happy Customers, who are pleased with our services.</p>
            </div>
          </section>
        </section>
        <Footer />
      </div>
    </>
  );
}

export default About;
