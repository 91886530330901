import { Link } from "react-router-dom";

import './Filter.css';
import Header from '../../Components/Shared/Header/Header';
import Footer from '../../Components/Shared/Footer/Footer';

import Search from "../../Components/Shared/Search/Search";

function Filter() {
    return (
        <>
            <div className="gla_page" id="gla_page">
                <a href="#gla_page" className="gla_top ti ti-angle-up gla_go"></a>
                <Header />
                <div className="gla_page_title gla_image_bck gla_wht_txt" data-color="#282828">
                    <div className="container text-left">
                        <div className="row">
                            <div className="col-md-8">
                                <h1 className="gla_h1_title">Search</h1>
                            </div>
                            <div className="col-md-4">
                                <div className="breadcrumbs">
                                    <a href="#">Home</a><span>Search</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section id="gla_content" className="gla_content">
                    <section className="gla_section">
                        <div className="container">

                            <div className="row">
                                <div className="col-md-12 col-xs-12">
                                    <Search />
                                </div>
                            </div>
                        </div>
                    </section>
                </section>

                <Footer />
            </div>
        </>
    );
}

export default Filter;
