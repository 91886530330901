import "./userProfile.css";
import Header from "../../Components/Shared/Header/Header";
import Footer from "../../Components/Shared/Footer/Footer";
import { useEffect, useState } from "react";
import VivahApi from "../../api";
import { calculateAgeInYearsAndMonths } from "../../utils/common";

function UserProfile(props) {
  // console.log(id)
  const regex = /(?<!^).(?!$)/g;

  const [filterProfiles, setFilterProfiles] = useState({});

  useEffect(() => {
    const init = async () => {
      const res = await VivahApi.Auth.getUserProfileData();
      // console.log("test", res.data);
      setFilterProfiles(res.data);
    };
    init();
  }, []);

  const handleClick = async () => {
    const addInterest = await VivahApi.Interest.addInterest(
      filterProfiles.userId
    );
  };

  return (
    <>
      <div className="gla_page" id="gla_page">
        <a href="#gla_page" className="gla_top ti ti-angle-up gla_go"></a>
        <Header />
        <div
          className="gla_page_title gla_image_bck gla_wht_txt"
          data-color="#282828"
        >
          <div className="container text-left">
            <div className="row">
              <div className="col-md-8">
                <h1 className="gla_h1_title">My Profile</h1>
              </div>
              <div className="col-md-4">
                <div className="breadcrumbs">
                  <a href="#">Home</a>
                  <span>My Profile</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section id="gla_content" className="gla_content">
          <section className="gla_section">
            <div className="container">
              <div className="row">
                <div className="col-md-12 col-xs-12">
                  <div className="row product_inside">
                    <div className="col-md-4 col-sm-4 col-xs-12">
                      <div className="gla_shop_item_slider">
                        <img
                          src={
                            filterProfiles.photo || "assets/images/profile.png"
                          }
                          alt=""
                        />
                        <a
                          className="btn btn-default update-btn"
                          href="/update-profile"
                        >
                          Edit
                        </a>
                      </div>
                    </div>
                    <div className="col-md-8 col-sm-8 col-xs-12">
                      <h3 className="title">
                        {filterProfiles.user?.firstName}
                        {"  "}
                        {filterProfiles.user?.lastName}
                      </h3>
                      <div className="meta-box clearfix">
                        <i className="ti ti-calendar"></i>{" "}
                        {calculateAgeInYearsAndMonths(
                          new Date(
                            filterProfiles.user ? (
                              filterProfiles.user.dateOfBirth
                            ) : (
                              <span style={{ color: "red" }}>
                                Not Specified
                              </span>
                            )
                          )
                        )}
                        {"   "}{" "}
                        {filterProfiles.height ? (
                          filterProfiles.height.height
                        ) : (
                          <span style={{ color: "red" }}>Not Specified</span>
                        )}
                        <br />
                        <i className="ti ti-direction-alt"></i> Mother Tongue is{" "}
                        {filterProfiles.motherTongue ? (
                          filterProfiles.motherTongue.name
                        ) : (
                          <span style={{ color: "red" }}>Not Specified</span>
                        )}{" "}
                        <br />
                        <i className="ti ti-shine"></i>{" "}
                        {filterProfiles.religion ? (
                          filterProfiles.religion.name
                        ) : (
                          <span style={{ color: "red" }}>Not Specified</span>
                        )}{" "}
                        <br />
                        <i className="ti ti-location-pin"></i> Lives in{" "}
                        {filterProfiles.state ? (
                          filterProfiles.state.name
                        ) : (
                          <span style={{ color: "red" }}>Not Specified</span>
                        )}
                        ,{" "}
                        {filterProfiles.city ? (
                          filterProfiles.city.name
                        ) : (
                          <span style={{ color: "red" }}>Not Specified</span>
                        )}{" "}
                        <br />
                        <i className="ti ti-shield"></i> Studied{" "}
                        {filterProfiles.qualification ? (
                          filterProfiles.qualification.name
                        ) : (
                          <span style={{ color: "red" }}>Not Specified</span>
                        )}{" "}
                        <br />
                        <i className="ti ti-medall"></i> Profession is{" "}
                        {filterProfiles.profession ? (
                          filterProfiles.profession.name
                        ) : (
                          <span style={{ color: "red" }}>Not Specified</span>
                        )}{" "}
                        <br />
                      </div>

                      {/* <div className="add-to-box">
                        <button
                          className="button btn btn-success mr-3"
                          title="Send Interest"
                          type="button"
                          onClick={handleClick}
                        >
                          Send Interest
                        </button>
                        <a className="link-wishlist btn btn-default">
                          Add to Favourite
                        </a>
                      </div> */}

                      {/* <div className="gla_tags">
                        <label>Hobbies & Interests </label>
                        <span>:</span>
                        <a href="#">Not Specified</a>
                        <a href="#">KKKG</a>
                      </div> */}
                      <br />
                      {/* <p className="gla_project_soc">
                        <a href="#">
                          <i className="ti ti-facebook"></i>
                        </a>
                        <a href="#">
                          <i className="ti ti-instagram"></i>
                        </a>
                        <a href="#">
                          <i className="ti ti-twitter"></i>
                        </a>
                      </p> */}
                    </div>
                    <div className="row about-block pl-2">
                      <div className="col-xs-12">
                        <h4>About</h4>
                        <p>
                          {filterProfiles.aboutYourself ? (
                            filterProfiles.aboutYourself
                          ) : (
                            <span style={{ color: "red" }}>Not Specified</span>
                          )}
                        </p>
                      </div>
                      <div className="col-xs-12">
                        <h4>Contact Numbers</h4>
                        <p>
                          {filterProfiles.user ? (
                            filterProfiles.user?.phone
                          ) : (
                            <span style={{ color: "red" }}>Not Specified</span>
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                  <ul id="myTab" className="nav nav-tabs" role="tablist">
                    <li role="presentation" className="active">
                      <a
                        href="#basic"
                        id="home-tab"
                        role="tab"
                        data-toggle="tab"
                        aria-controls="basic"
                        aria-expanded="true"
                      >
                        Basics & Lifestyle
                      </a>
                    </li>
                    <li role="presentation" className="">
                      <a
                        href="#religious"
                        id="religious-tab"
                        role="tab"
                        data-toggle="tab"
                        aria-controls="religious"
                        aria-expanded="true"
                      >
                        Religious Background
                      </a>
                    </li>
                    <li role="presentation" className="">
                      <a
                        href="#family"
                        id="family-tab"
                        role="tab"
                        data-toggle="tab"
                        aria-controls="family"
                        aria-expanded="true"
                      >
                        Family details
                      </a>
                    </li>
                    <li role="presentation" className="">
                      <a
                        href="#education"
                        id="education-tab"
                        role="tab"
                        data-toggle="tab"
                        aria-controls="education"
                        aria-expanded="true"
                      >
                        Education & Career
                      </a>
                    </li>
                  </ul>
                  <div id="myTabContent" className="tab-content">
                    <div
                      role="tabpanel"
                      className="tab-pane fade in active"
                      id="basic"
                      aria-labelledBy="basic-tab"
                    >
                      <div className="row text-left">
                        <div className="col-md-6">
                          <strong>Age: </strong>
                          {filterProfiles.user?.dateOfBirth
                            ? calculateAgeInYearsAndMonths(
                                new Date(filterProfiles.user?.dateOfBirth)
                              )
                            : "Not Specified"}
                        </div>
                        <div className="col-md-6">
                          <strong>Date of Birth: </strong>
                          {filterProfiles.user?.dateOfBirth
                            ? new Date(
                                filterProfiles.user?.dateOfBirth
                              ).toLocaleDateString()
                            : "Not Specified"}
                        </div>
                        <div className="col-md-6">
                          <strong>Height: </strong>
                          {filterProfiles.height
                            ? filterProfiles.height?.height
                            : "Not Specified"}
                        </div>
                        <div className="col-md-6">
                          <strong>Marital Status : </strong>
                          {filterProfiles.maritalStatus
                            ? filterProfiles.maritalStatus?.maritalStatus
                            : "Not Specified"}
                        </div>
                        <div className="col-md-6">
                          <strong>Body Weight: </strong>
                          {filterProfiles.bodyWeight
                            ? filterProfiles.bodyWeight
                            : "Not Specified"}
                        </div>
                        <div className="col-md-6">
                          <strong>Complexion: </strong>
                          {filterProfiles.complexion
                            ? filterProfiles.complexion
                            : "Not Specified"}
                        </div>
                        <div className="col-md-6">
                          <strong>Diet: </strong>
                          {filterProfiles.diet
                            ? filterProfiles.diet
                            : "Not Specified"}
                        </div>
                        <div className="col-md-6">
                          <strong>Blood Group: </strong>
                          {filterProfiles.bloodGroup
                            ? filterProfiles.bloodGroup.bloodGroupName
                            : "Not Specified"}
                        </div>
                        <div className="col-md-6">
                          <strong>Health Information: </strong>
                          {filterProfiles.healthInformation
                            ? filterProfiles.healthInformation.healthInfo
                            : "Not Specified"}
                        </div>
                        <div className="col-md-6">
                          <strong>Disability: </strong>
                          {filterProfiles.disability
                            ? filterProfiles.disability.disability
                            : "Not Specified"}
                        </div>
                        <div className="col-md-6">
                          <strong>Body Type : </strong>
                          {filterProfiles.bodyType
                            ? filterProfiles.bodyType.name
                            : "Not Specified"}
                        </div>
                        <div className="col-md-6">
                          <strong>Can Speak: </strong>
                          {filterProfiles.motherTongue
                            ? filterProfiles.motherTongue.name
                            : "Not Specified"}
                        </div>
                      </div>
                    </div>
                    <div
                      role="tabpanel"
                      className="tab-pane fade in"
                      id="religious"
                      aria-labelledBy="basic-tab"
                    >
                      <div className="row text-left">
                        <div className="col-md-6">
                          <strong>Religion: </strong>
                          {filterProfiles.religion
                            ? filterProfiles.religion?.name
                            : "Not Specified"}
                        </div>
                        <div className="col-md-6">
                          <strong>Caste: </strong>
                          {filterProfiles.caste
                            ? filterProfiles.caste?.name
                            : "Not Specified"}
                        </div>
                        <div className="col-md-6">
                          <strong>Gothra: </strong>
                          {filterProfiles.gothra
                            ? filterProfiles.gothra?.name
                            : "Not Specified"}
                        </div>
                        <div className="col-md-6">
                          <strong>Mother Tongue : </strong>
                          {filterProfiles.motherTongue
                            ? filterProfiles.motherTongue?.name
                            : "Not Specified"}
                        </div>
                        <div className="col-md-6">
                          <strong>Time of Birth: </strong>
                          {filterProfiles.birthTime
                            ? filterProfiles.birthTime
                            : "Not Specified"}
                        </div>
                        <div className="col-md-6">
                          <strong>Place Of Birth: </strong>
                          {filterProfiles.birthPlace
                            ? filterProfiles.birthPlace
                            : "Not Specified"}
                        </div>
                      </div>
                    </div>
                    <div
                      role="tabpanel"
                      className="tab-pane fade in"
                      id="family"
                      aria-labelledBy="basic-tab"
                    >
                      <div className="row text-left">
                        <div className="col-md-6">
                          <strong>Father Name: </strong>
                          {filterProfiles.fatherName
                            ? filterProfiles.fatherName
                            : "Not Specified"}
                        </div>
                        <div className="col-md-6">
                          <strong>Father's Occupation: </strong>
                          {filterProfiles.fatherOccupation
                            ? filterProfiles.fatherOccupation.name
                            : "Not Specified"}
                        </div>
                        <div className="col-md-6">
                          <strong>Mother Name: </strong>
                          {filterProfiles.motherName
                            ? filterProfiles.motherName
                            : "Not Specified"}
                        </div>
                        <div className="col-md-6">
                          <strong>Mother's Occupation : </strong>
                          {filterProfiles.motherOccupation
                            ? filterProfiles.motherOccupation.name
                            : "Not Specified"}
                        </div>
                        <div className="col-md-6">
                          <strong>No of Brothers : </strong>
                          {filterProfiles.brother
                            ? filterProfiles.brother
                            : "Not Specified"}
                        </div>
                        <div className="col-md-6">
                          <strong>No of Sisters: </strong>
                          {filterProfiles.sister
                            ? filterProfiles.sister
                            : "Not Specified"}
                        </div>
                        <div className="col-md-6">
                          <strong>Family Values: </strong>
                          {filterProfiles.familyValues
                            ? filterProfiles.familyValues.name
                            : "Not Specified"}
                        </div>
                        {/* <div className="col-md-6">
                          <strong>Family Type: </strong>
                          {filterProfiles.familyValues
                            ? filterProfiles.familyValues.name
                            : "Not Specified"}
                        </div> */}
                        <div className="col-md-6">
                          <strong>Family Status: </strong>
                          {filterProfiles.familyStatus
                            ? filterProfiles.familyStatus.name
                            : "Not Specified"}
                        </div>
                        <div className="col-md-6">
                          <strong>Family Location: </strong>
                          {filterProfiles.familyCity ? (
                            filterProfiles.familyCity.name
                          ) : (
                            <span>Not Specified</span>
                          )}
                        </div>
                        {/* <div className="col-md-6">
                          <strong>Native Place : </strong>
                          {filterProfiles.nativePlaceId ? (
                            filterProfiles.nativePlaceId
                          ) : (
                            <span>Not Specified</span>
                          )}
                        </div> */}
                      </div>
                    </div>
                    <div
                      role="tabpanel"
                      className="tab-pane fade in"
                      id="education"
                      aria-labelledBy="basic-tab"
                    >
                      <div className="row text-left">
                        <div className="col-md-6">
                          <strong>Highest Qualification: </strong>{" "}
                          {filterProfiles.qualification
                            ? filterProfiles.qualification?.name
                            : "Not Specified"}
                        </div>
                        <div className="col-md-6">
                          <strong>College(s) Attended : </strong>
                          {filterProfiles.collegeName
                            ? filterProfiles.collegeName
                            : "Not Specified"}
                        </div>
                        <div className="col-md-6">
                          <strong>Working With: </strong>{" "}
                          {filterProfiles.professionType
                            ? filterProfiles.professionType?.name
                            : "Not Specified"}
                        </div>
                        <div className="col-md-6">
                          <strong>Working As : </strong>
                          {filterProfiles.profession
                            ? filterProfiles.profession?.name
                            : "Not Specified"}
                        </div>
                        <div className="col-md-6">
                          <strong>Company Name : </strong>
                          {filterProfiles.companyName
                            ? filterProfiles.companyName
                            : "Not Specified"}
                        </div>
                        <div className="col-md-6">
                          <strong>Annual Income: </strong>
                          {filterProfiles.income
                            ? filterProfiles.income?.name
                            : "Not Specified"}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>

        <Footer />
      </div>
    </>
  );
}

export default UserProfile;
