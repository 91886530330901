import createAxiosClient, { config } from "../utils/api-utils";
class ChatApi {
  constructor() {
    this.client = createAxiosClient();
  }
  async addMsg(chat) {
    return await this.client.post("/vvh-messages", chat, config());
  }

  async getMsgByUser(user) {
    // console.log(user, "test user");
    return await this.client.post(
      `/vvh-messages-list`,
      {
        id: user.id,
        name: user.firstName,
      },
      config()
    );
  }

  async getChatList() {
    return await this.client.get("/vvh-user-messages", config()); //shows count
  }
}
export default ChatApi = new ChatApi();
