import './PrivacyPolicy.css';
import Header from '../../Components/Shared/Header/Header';
import Footer from '../../Components/Shared/Footer/Footer';

function PrivacyPolicy() {
  return (
      <>
        <div classNameName="gla_page" id="gla_page">
            <a href="#gla_page" classNameName="gla_top ti ti-angle-up gla_go"></a>
            <Header />
            
            <div className="gla_page_title gla_image_bck gla_wht_txt" data-color="#282828">
                <div className="container text-left">
                <div className="row">
                    <div className="col-md-8">
                    <h1 className="gla_h1_title">Privacy Policy</h1>
                    </div>
                    <div className="col-md-4">
                    <div className="breadcrumbs">
                        <a href="/">Home</a><span>Privacy Policy</span>
                    </div>
                    </div>
                </div>
                </div>
                </div>
            <section id="gla_content" className="gla_content">
            <section className="gla_section">
                <div className="container">

                <div className="row">
                    <div className="col-md-12 col-xs-12">   
                        <div className="gla_post_info">
                            DEVELOPED BY
                        </div>
                        <p>
                            Vivahbandh.com is developed by the trusted company <a href='#'>Fruzan Technologies</a>, which is Registered and also verified by Google with it's Location and contacts. We are committed for privacy and protection for your online identifiable information you share with us.
                        </p>
                        <div className="gla_post_info">
                            CONFIDENTIALITY OF PROFILE
                        </div>
                        <p>
                            The users we registered on our website are verified with their Email. The Information we takes from the user is fully related with their demand for the marriage purposal. We Keep it confidential and not share it with any harmful and malicious people. We are under the law and order regarding the Privacy of Information of user's profile data.
                        </p>

                        <div className="gla_post_info">
                            HOW WE USE USER'S INFORMATION
                        </div>
                        <p>
                            We use Information of user with their convenience, the compulsory fields in our registration form are only for user verification. We never made every field compulsory. Only the information that user want to share for his purpose of marriage he can share that much, the other information is not compulsory to enter. The information we keep in our database is safe and we binded our domain with SSL certificate, where the security concern matters.
                        </p>
                        
                        <div className="gla_post_info">
                            OTHER PRIVACY POLICIES WE BOUNDED WITH
                        </div>
                        <ul>
                            <li>We do not register any user less than 18 years old.</li>
                            <li>We do not take any personal documents of the users.</li>
                            <li>We use credentials and sessions for logins.</li>
                            <li>Some of our pages uses browser storage for user fast navigation.</li>
                            <li>We only use Third party service(razorpay) for the payment which is legal and authentic.</li>
                            <li>We do not share any user photos and information & don't display it without login.</li>
                        </ul>
                    </div>
                </div>  
                </div>
            </section>
            </section>

            <Footer />
        </div>
      </>
  );
}

export default PrivacyPolicy;
