import './CancellationRefund.css';
import Header from '../../Components/Shared/Header/Header';
import Footer from '../../Components/Shared/Footer/Footer';

function CancellationRefund() {
  return (
      <>
        <div classNameName="gla_page" id="gla_page">
            <a href="#gla_page" classNameName="gla_top ti ti-angle-up gla_go"></a>
            <Header />
            
            <div className="gla_page_title gla_image_bck gla_wht_txt" data-color="#282828">
                <div className="container text-left">
                <div className="row">
                    <div className="col-md-8">
                    <h1 className="gla_h1_title">CANCELLATION & REFUND</h1>
                    </div>
                    <div className="col-md-4">
                    <div className="breadcrumbs">
                        <a href="/">Home</a><span>CANCELLATION & REFUND</span>
                    </div>
                    </div>
                </div>
                </div>
                </div>
            <section id="gla_content" className="gla_content">
            <section className="gla_section">
                <div className="container">

                <div className="row">
                    <div className="col-md-12 col-xs-12">   
                        <div className="gla_post_info">
                            CANCELLATION POLICY
                        </div>
                        <p>
                        ou may terminate your vivahbandh matrimony subscription at any time, for any reason. But the payments made by the customer by way of registration/membership/renewal fee/auto renewal is /are treated as non-refundable.
                        </p>
                        <div className="gla_post_info">
                            REFUND POLICY
                        </div>
                        <p>
                            At any moment if you terminate your subscription, you will not be entitled to a refund of any subscription fees, if any, paid by you.
                        </p>

                    </div>
                </div>  
                </div>
            </section>
            </section>

            <Footer />
        </div>
      </>
  );
}

export default CancellationRefund;
