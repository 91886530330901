import "./Registration.css";
import Header from "../../Components/Shared/Header/Header";
import Footer from "../../Components/Shared/Footer/Footer";
import { useForm } from "react-hook-form";
import axios from "axios";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import Auth from "../../utils/api-utils";
import VivahApi from "../../api";
import { getUniqueRes } from "../../utils/api-utils";
import { calculateAge } from "../../utils/common";
import * as moment from "moment";

function Registration() {
  const formSchema = Yup.object().shape({
    user: Yup.object().shape({
      password: Yup.string()
        .required("Password is required")
        .min(8, "Password must be at 8 char long"),
      confirmPwd: Yup.string()
        .required("Password is required")
        .oneOf([Yup.ref("password")], "Passwords does not match"),
      email: Yup.string()
        .required("email is required")
        .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/i, "Enter Valid Email"),
      phone: Yup.string()
        .required("Phone Number is required")
        .matches(
          /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
          "Enter Valid Phone Number"
        ),
      firstName: Yup.string().required("First Name is required"),
      lastName: Yup.string().required("Last Name is required"),
      dateOfBirth: Yup.date()
        .max(
          new Date(Date.now() - 567648000000),
          "You must be at least 18 years"
        )
        .required("Required"),
    }),
    profile: Yup.object().shape({
      religionId: Yup.string().required("Religion is required"),
      casteId: Yup.string().required("Caste is required"),
      gender: Yup.string().required("Gender is required"),
      motherTongueId: Yup.string().required("MotherTongue required"),
    }),
  });
  const formOptions = { resolver: yupResolver(formSchema) };

  const { register, handleSubmit, formState, watch } = useForm(formOptions);
  const { errors } = formState;
  const [religionId, setreligionId] = useState([]);
  const [casteId, setcasteId] = useState([]);
  const [gothraId, setGothraId] = useState([]);
  const [motherTongueId, setMotherTongueId] = useState([]);
  const [showGothras, setShowGothras] = useState(false);
  const [agree, setAgree] = useState(false);
  const [isReg, setIsReg] = useState(false);
  let watchval = watch();
  // console.log(watchval, "watchval");

  useEffect(() => {
    const init = async () => {
      const res = await VivahApi.Religion.listReligionWithCaste();
      const resGothra = await VivahApi.Religion.listGothras();
      const resMotherTongue = await VivahApi.Religion.listMotherTongues();
      //console.log(res);
      setreligionId(res.data);
      setGothraId(resGothra.data);
      setMotherTongueId(resMotherTongue.data);
    };
    init();
  }, []);

  const handleReligionChange = (e) => {
    // console.log(e.target.value);
    const rel = religionId.find((r) => r.sid == e.target.value);
    // console.log(rel);
    setShowGothras(rel.name == "Hindu");
    setcasteId(rel.castes);
  };
  const onSubmit = async (data) => {
    // console.log(data, "signupdata");
    // return;
    const isHindu = religionId.find((r) => r.name == "Hindu");
    // console.log(isHindu, "ishindu");
    if (isHindu.sid != data.profile.religionId) {
      // console.log(!isHindu)
      data.profile.gothraId = 0;
    }
    delete data.user.confirmPwd;
    // const profile = getUniqueRes(data.profile)
    const user = data.user;
    user.dateOfBirth = moment(data?.user?.dateOfBirth).format("YYYY-MM-DD");
    const resUser = await VivahApi.Auth.signUp(user);
    // console.log(resUser)

    data.profile = {
      ...data.profile,
      userId: resUser.data.id,
      casteId: Number(data.profile.casteId),
      religionId: Number(data.profile.religionId),
      gothraId: Number(data.profile.gothraId || 0),
      motherTongueId: Number(data.profile.motherTongueId),
      age: calculateAge(new Date(data.user?.dateOfBirth)),
    };
    // console.log(data.profile)
    const resProfile = await VivahApi.Auth.signUpProfile(data.profile);
    if (resProfile) {
      setIsReg(true);
      setTimeout(() => {
        window.location.href = "/login";
      }, 3000);
    }
  };

  const cancel = () => {
    window.location.href = "/login";
  };

  return (
    <>
      <div className="gla_page" id="gla_page">
        <a href="#gla_page" className="gla_top ti ti-angle-up gla_go"></a>
        <Header />
        <div
          className="gla_page_title gla_image_bck gla_wht_txt"
          data-color="#282828"
        >
          <div className="container text-left">
            <div className="row">
              <div className="col-md-8">
                <h1 className="gla_h1_title">Sign Up</h1>
              </div>
              <div className="col-md-4">
                <div className="breadcrumbs">
                  <a href="#">Home</a>
                  <span>Sign Up</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section id="gla_content" className="gla_content">
          <section className="gla_section">
            <div className="container">
              <h3>Sign Up</h3>
              <div className="row">
                <div className="col-md-8 col-md-push-2">
                  {/* <form action="https://formspree.io/your@mail.com" method="POST"> */}
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                      <div className="col-md-6">
                        <label>Your First Name*</label>
                        <input
                          type="text"
                          name="firstName"
                          {...register("user.firstName")}
                          className={`form-control form-opacity ${
                            errors.user && errors.user.firstName
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <span className="invalid-feedback">
                          {errors.user && errors.user.firstName?.message}
                        </span>
                      </div>
                      <div className="col-md-6">
                        <label>Your Last Name*</label>
                        <input
                          type="text"
                          name="lastName"
                          {...register("user.lastName")}
                          className={`form-control form-opacity ${
                            errors.user && errors.user.lastName
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <span className="invalid-feedback">
                          {errors.user && errors.user.lastName?.message}
                        </span>
                      </div>
                      <div className="col-md-4">
                        <label>Date of Birth*</label>
                        <input
                          type="date"
                          name="dateOfBirth"
                          {...register("user.dateOfBirth")}
                          className={`form-control form-opacity  ${
                            errors.user && errors.user.dateOfBirth
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <span className="invalid-feedback">
                          {errors.user && errors.user.dateOfBirth?.message}
                        </span>
                      </div>
                      <div className="col-md-4">
                        <label>Gender*</label>
                        <select
                          name="gender"
                          {...register("profile.gender")}
                          className={`form-control form-opacity border-radius-0 ${
                            errors.profile && errors.profile.dateOfBirth
                              ? "is-invalid"
                              : ""
                          }`}
                        >
                          <option value="">Select Gender</option>
                          <option value="M">Male</option>
                          <option value="F">Female</option>
                          <option value="T">Transgender</option>
                        </select>
                        <span className="invalid-feedback">
                          {errors.profile && errors.profile.gender?.message}
                        </span>
                      </div>
                      <div className="col-md-4">
                        <label>Religion*</label>
                        <select
                          name="religion"
                          {...register("profile.religionId")}
                          className={`form-control form-opacity border-radius-0 ${
                            errors.profile && errors.profile.religionId
                              ? "is-invalid"
                              : ""
                          }`}
                          onChange={handleReligionChange}
                        >
                          <option value="">Select Religion</option>
                          {religionId.map((e) => {
                            return (
                              <option key={e.sid} value={e.sid}>
                                {e.name}
                              </option>
                            );
                          })}
                        </select>
                        <span className="invalid-feedback">
                          {errors.profile && errors.profile.religionId?.message}
                        </span>
                      </div>
                      <div className="col-md-4">
                        <label>Caste*</label>
                        <select
                          name="caste"
                          {...register("profile.casteId")}
                          className={`form-control form-opacity border-radius-0 ${
                            errors.profile && errors.profile.casteId
                              ? "is-invalid"
                              : ""
                          }`}
                        >
                          <option value="">Select caste</option>
                          {casteId.map((e) => {
                            return (
                              <option key={e.sid} value={e.sid}>
                                {e.name}
                              </option>
                            );
                          })}
                        </select>
                        <span className="invalid-feedback">
                          {errors.profile && errors.profile.religionId?.message}
                        </span>
                      </div>
                      {showGothras && (
                        <div className="col-md-4">
                          <label>Gothra*</label>
                          <select
                            {...register("profile.gothraId")}
                            className={`form-control form-opacity border-radius-0 ${
                              errors.profile && errors.profile.gothraId
                                ? "is-invalid"
                                : ""
                            }`}
                          >
                            <option value="">Select Gothra</option>
                            {gothraId.map((e) => {
                              return (
                                <option key={e.sid} value={e.sid}>
                                  {e.name}
                                </option>
                              );
                            })}
                          </select>
                          <span className="invalid-feedback">
                            {errors.profile && errors.profile.gothraId?.message}
                          </span>
                        </div>
                      )}
                      <div className="col-md-4">
                        <label>Mother Tongue*</label>
                        <select
                          {...register("profile.motherTongueId")}
                          className={`form-control form-opacity border-radius-0 ${
                            errors.profile && errors.profile.motherTongueId
                              ? "is-invalid"
                              : ""
                          }`}
                        >
                          <option value="">Select Mother Tongue</option>
                          {motherTongueId.map((e) => {
                            return (
                              <option key={e.sid} value={e.sid}>
                                {e.name}
                              </option>
                            );
                          })}
                        </select>
                        <span className="invalid-feedback">
                          {errors.profile &&
                            errors.profile.motherTongueId?.message}
                        </span>
                      </div>
                      <div className="col-md-6">
                        <label>Your e-mail*</label>
                        <input
                          type="text"
                          name="email"
                          {...register("user.email")}
                          className={`form-control form-opacity ${
                            errors.user && errors.user.email ? "is-invalid" : ""
                          }`}
                        />
                        <span className="invalid-feedback">
                          {errors.user && errors.user.email?.message}
                        </span>
                      </div>
                      <div className="col-md-6">
                        <label>Your Mobile*</label>
                        <input
                          type="text"
                          name="phone"
                          {...register("user.phone")}
                          className={`form-control form-opacity ${
                            errors.user && errors.user.phone ? "is-invalid" : ""
                          }`}
                        />
                        <span className="invalid-feedback">
                          {errors.user && errors.user.phone?.message}
                        </span>
                      </div>
                      <div className="col-md-6">
                        <label>Password*</label>
                        <input
                          type="password"
                          name="password"
                          {...register("user.password")}
                          className={`form-control form-opacity ${
                            errors.user && errors.user.password
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <span className="invalid-feedback">
                          {errors.user && errors.user.password?.message}
                        </span>
                      </div>
                      <div className="col-md-6">
                        <label>Confirm Password*</label>
                        <input
                          type="password"
                          name="confirmPwd"
                          {...register("user.confirmPwd")}
                          className={`form-control form-opacity ${
                            errors.user && errors.user.confirmPwd
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <span className="invalid-feedback">
                          {errors.user && errors.user.confirmPwd?.message}
                        </span>
                      </div>
                      <div className="col-md-12">
                        <input
                          type="checkbox"
                          name="agree"
                          checked={agree}
                          onChange={(e) => setAgree(!agree)}
                        />
                        <span>
                          I Agree to Vivahbandh{" "}
                          <a href="/terms-conditions" target="_blanck">
                            Terms & Condition
                          </a>
                        </span>
                        <br />
                        <span className="invalid-feedback">
                          {errors.user && errors.agree?.message}
                        </span>
                      </div>
                      <div className="col-md-12">
                        <input
                          type="submit"
                          className="btn submit"
                          value="Sign Up"
                          disabled={!agree}
                        />
                        <input
                          type="button"
                          className="btn btn-danger ml-3"
                          value="Cancel"
                          onClick={() => cancel()}
                        />
                        {isReg && (
                          <div className="reg-success">
                            Registration successfull.
                          </div>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </section>
        <Footer />
      </div>
    </>
  );
}

export default Registration;
