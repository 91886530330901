import createAxiosClient, { config } from "../utils/api-utils";

class FilterApi {
    constructor() {
        this.client = createAxiosClient();
    }
    async saveFilter(data) {
        return await this.client.post("/vvh-user-filters-custom", data, config());
    }

    async getFilter() {
        return await this.client.get("/vvh-user-filters-custom", config());
    }

}
export default FilterApi = new FilterApi();