import { useState, useEffect } from "react";

import { Link } from "react-router-dom";
import VivahApi from "../../api";
import "./Upload.css";
import Header from "../../Components/Shared/Header/Header";
import Footer from "../../Components/Shared/Footer/Footer";

function Upload() {
  const [file, setFile] = useState("");
  const [imagePreviewUrl, setImagePreviewUrl] = useState("");
  const [imagePreview, setImagePreview] = useState(null);
  const [cImagePreviewUrl, setcImagePreviewUrl] = useState("");
  const [cImagePreview, setcImagePreview] = useState(null);
  const [type, setType] = useState("");

  useEffect(() => {
    if (imagePreviewUrl) {
      setImagePreview(<img src={imagePreviewUrl} />);
    } else {
      setImagePreview(
        <div className="previewText">Please select an Image for Preview</div>
      );
    }

    if (cImagePreviewUrl) {
      setcImagePreview(<img src={cImagePreviewUrl} />);
    } else {
      setcImagePreview(
        <div className="previewText">
          Please select an Cover Image for Preview
        </div>
      );
    }
  }, [imagePreviewUrl, cImagePreviewUrl]);

  const _handleSubmit = (e) => {
    e.preventDefault();
    // console.log('handle uploading-', file);
  };

  const submit = async (event) => {
    if (!file) {
      alert("please choose file");
      return;
    }
    event.preventDefault();
    const formData = new FormData();
    formData.append("image", file);
    formData.append("type", type);
    try {
      const res = await VivahApi.UploadPic.uploadImg(formData);
      alert("Image uploaded!");
    } catch (error) {
      console.log(error);
    }

    //  await axios.post("/upload-img", formData, { headers: {'Content-Type': 'multipart/form-data'}})
  };

  const handleImageChange = (e) => {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      setFile(file);
      setImagePreviewUrl(reader.result);
    };

    reader.readAsDataURL(file);
  };

  const handleCoverImageChange = (e) => {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      setFile(file);
      setcImagePreviewUrl(reader.result);
    };

    reader.readAsDataURL(file);
  };

  return (
    <>
      <div className="gla_page" id="gla_page">
        <a href="#gla_page" className="gla_top ti ti-angle-up gla_go"></a>
        <Header />
        <div
          className="gla_page_title gla_image_bck gla_wht_txt"
          data-color="#282828"
        >
          <div className="container text-left">
            <div className="row">
              <div className="col-md-8">
                <h1 className="gla_h1_title">Upload</h1>
              </div>
              <div className="col-md-4">
                <div className="breadcrumbs">
                  <a href="#">Home</a>
                  <span>Upload</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section id="gla_content" className="gla_content">
          <section className="gla_section upload">
            <div className="container pb-0">
              <h3>Upload Profile Pic</h3>
              <div className="row">
                <div className="col-md-6 col-xs-12">
                  <form className="" onSubmit={submit}>
                    <input
                      className="fileInput"
                      type="file"
                      onChange={(e) => {
                        setFile(e.target.files[0]);
                        setType("profile");
                      }}
                    />
                    <button className="btn submit" type="submit">
                      Upload Picture
                    </button>
                  </form>
                </div>
                <div className="col-md-6 col-xs-12">
                  <div className="imgPreview">{imagePreview}</div>
                </div>
              </div>
            </div>
            <div className="container">
              <h3>Upload Cover Pic</h3>
              <div className="row">
                <div className="col-md-6 col-xs-12">
                  <form className="" onSubmit={submit}>
                    <input
                      className="fileInput"
                      type="file"
                      onChange={(e) => {
                        setFile(e.target.files[0]);
                        setType("cover");
                      }}
                    />

                    <button className="btn submit" type="submit">
                      Upload Cover Picture
                    </button>
                  </form>
                </div>
                <div className="col-md-6 col-xs-12">
                  <div className="imgPreview">{cImagePreview}</div>
                </div>
              </div>
            </div>
          </section>
        </section>

        <Footer />
      </div>
    </>
  );
}

export default Upload;
