import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "./Header.css";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const user = 1;
  const navigate = useNavigate();
  const refreshPage = () => {
    setTimeout(() => {
      window.location.reload(false);
    }, 0);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <header>
      <nav className="gla_light_nav gla_transp_nav">
        <div className="container">
          <div className="gla_logo_container clearfix">
            <a href="/">
              <img
                src="/assets/images/logo.png"
                alt=""
                className="gla_logo_rev"
              />
            </a>
          </div>

          <div className="gla_main_menu gla_main_menu_mobile">
            <div className="gla_main_menu_icon">
              <i></i>
              <i></i>
              <i></i>
              <i></i>
              <b>Admin</b>
            </div>
          </div>

          <div
            className="gla_main_menu_content gla_image_bck"
            data-color="rgba(0,0,0,0.9)"
          >
            <div
              className="gla_over"
              data-color="#000"
              data-opacity="0.7"
            ></div>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
