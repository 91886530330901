import React from 'react';
import { Link } from "react-router-dom";
import './Footer.css';

function Footer() {
   const refreshPage = () => {
        setTimeout(()=>{
            window.location.reload(false);
        }, 0);
    }
    return (
        <>
        <section id="black" className="gla_section gla_image_bck gla_wht_txt footer-back">
            <div className="container">
                <div className="row">
                    <div className="col-md-5 col-sm-5">
                        <h4>VivahBandh.com</h4>
                        <p>
                        India's Most Trusted Matrimony service, has helped many matches from across different communities such as Agarwal, Brahmin, Kalita, Kayastha, Khandayat, Khatri, Lingayath, Maratha, Nair, Parsi, Rajput, Reddy.</p>
                    </div>
                    <div className="col-md-5 col-sm-5">
                        <ul className="list-unstyled">
                            <li><Link to="/about" onClick={refreshPage}>About Us</Link></li>
                            <li><Link to="/contact-us" onClick={refreshPage}>Conatct Us</Link></li>
                            <li><Link to="/privacy-policy" onClick={refreshPage}>Privacy Policy</Link></li>
                            <li><Link to="/terms-conditions" onClick={refreshPage}>Terms and Conditions</Link></li>
                            <li><Link to="/cancellation-refund" onClick={refreshPage}>Cancellation and Refund</Link></li>
                        </ul>
                    </div>
                </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="gla_block">
                                <p>© All Rights Reserved, Vivahbandh</p>
                            </div>
                        </div>
                    </div>
                
            </div>
    </section>
            
        </>
    );
}

export default Footer;