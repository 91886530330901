import axios from "axios";
import { BASE_URL } from "../constants/constant.js";

export default function createAxiosClient() {
  const axiosService = axios.create({
    baseURL: BASE_URL,
  });
  // Response interceptor for API calls
  // axiosService.interceptors.response.use((response) => {
  //     return response
  // }, async function (error) {
  //     if (error.response.status === 401 && error.response.data.error === "invalid_token") {
  //         Utils.removeToken();
  //         document.location.href = "/login";
  //     }
  //     return Promise.reject(error);
  // });
  return axiosService;
}
export function config() {
  const token = localStorage.getItem("token");

  return {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };
}

export function isAuthenticated() {
  const isAuthenticated = localStorage.getItem("usermame");
  return isAuthenticated;
}

export function isPlanPurchased() {
  const isPlanPurchased = localStorage.getItem("planName");
  //   console.log(isPlanPurchased, "pp");
  return isPlanPurchased;
}

export function isChatting() {
  const chatUser = localStorage.getItem("chatUser");
  //   console.log(isPlanPurchased, "pp");

  if (!chatUser) return null;

  return JSON.parse(chatUser);
}
export function getUniqueRes(profileData) {
  let obj = {};
  for (const key in profileData) {
    if (profileData[key] != null) {
      obj[key] = profileData[key];
    }
  }
  return obj;
}
