import createAxiosClient from "../utils/api-utils";

class PlanApi {
  constructor() {
    this.client = createAxiosClient();
  }
  async listPlan() {
    return await this.client.get("/vvh-plans");
  }
}
export default PlanApi = new PlanApi();
