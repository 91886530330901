import "./Home.css";
import Slider from "../../Components/Slider/Slider";
import Header from "../../Components/Shared/Header/Header";
import Footer from "../../Components/Shared/Footer/Footer";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import VivahApi from "../../api";
import PlanPurchase from "../plan/PlanPurchase";
import Plan from "../../api/Plan";
function Home() {
  // const [plan, setPlan] = useState();
  // const navigate = useNavigate();
  // const proceedCheckout = (plan) => {
  //   // sessionStorage.setItem("plan", plan);
  //   // window.location.href = "/checkout";
  //   console.log("Checkout Plan", plan);
  //   navigate("/checkout", { state: { data: plan } });
  // };

  // useEffect(() => {
  //   const init = async () => {
  //     const plan = await VivahApi.Plan.listPlan();
  //     console.log(plan.data, "plan");
  //     setPlan(plan.data);
  //   };
  //   init();
  // }, []);

  return (
    <>
      <div className="gla_page" id="gla_page">
        <a href="#gla_page" className="gla_top ti ti-angle-up gla_go"></a>
        <Header />
        <Slider />
        <section id="gla_content" className="gla_content">
          <section className="gla_section gla_image_bck" data-color="#f9fbf7">
            <div className="container text-center">
              <p>
                <img
                  src="images/animations/flowers.gif"
                  data-bottom-top="@src:images/animations/flowers.gif"
                  height="150"
                  alt=""
                />
              </p>
              <h2>Why Choose VivahBandh</h2>

              <ul className="text-left fs-2">
                <li>Most trusted matrimony service</li>
                <li>100% verified mobile numbers</li>
                <li>
                  Our largest number of profiles increases your chances of
                  meeting the right person
                </li>
                <li>Many have found their life partner here</li>
              </ul>
            </div>
          </section>

          <section
            className="gla_section gla_image_bck gla_fixed gla_wht_txt"
            data-stellar-background-ratio="0.8"
            data-image="assets/images/image4.jpg"
          >
            <div
              className="gla_over"
              data-color="#282828"
              data-opacity="0.4"
            ></div>

            <div className="container text-center">
              <p>
                <img
                  src="images/animations/savethedate_wh.gif"
                  data-bottom-top="@src:images/animations/savethedate_wh.gif"
                  height="150"
                  alt=""
                />
              </p>
              <h2>We help at every stage</h2>
              <h3 className="gla_subtitle">
                VivahBandh.com - India's Most Trusted Matrimony service, has
                helped many matches from across different communities such as
                Agarwal, Brahmin, Kalita, Kayastha, Khandayat, Khatri,
                Lingayath, Maratha, Nair, Parsi, Rajput, Reddy.
              </h3>
            </div>
          </section>

          <section className="gla_section">
            <div className="container text-center">
              <p>
                <img
                  src="images/animations/flowers2.gif"
                  data-bottom-top="@src:images/animations/flowers2.gif"
                  height="150"
                  alt=""
                />
              </p>
              <h3 className="gla_subtitle mt-2">
                Upgrade your membership to connect with those you like.
              </h3>
              <p>
                Search your perfect life-partner from many of trusted &
                authentic profiles.
              </p>
              {/* <PlanPurchase /> */}
              {/* <div className="gla_icon_boxes row text-left"> */}
              {/* {plan &&
                  plan.map((e) => (
                    <div className="col-md-3 col-sm-6">
                      <a className="gla_news_block">
                        <span className="gla_news_img">
                          <img src={e.img} />
                        </span>
                        <span className="gla_news_title">{e.planName}</span>
                        <p>
                          <strong>{e.durationDescription}s</strong>
                          <br />
                          {e.description}
                        </p>
                        <div className="package-btn">
                          <button
                            type="button"
                            className="btn btn-default"
                            onClick={() => proceedCheckout(e)}
                          >
                            {e.amount}
                          </button>
                        </div>
                      </a>
                    </div>
                  ))} */}
              {/* <div className="col-md-3 col-sm-6">
                  <a href="#" className="gla_news_block">
                    <span className="gla_news_img">
                      <img src="assets/Gold Package.png" />
                    </span>
                    <span className="gla_news_title">Gold Package</span>
                    <p>
                      <strong>6 Months</strong>
                      <br />
                      180 (Days)
                    </p>
                    <div className="package-btn">
                      <button
                        type="button"
                        className="btn btn-default"
                        onClick={() => proceedCheckout(2)}
                      >
                        Rs. 6000{" "}
                      </button>
                    </div>
                  </a>
                </div>

                <div className="col-md-3 col-sm-6">
                  <a href="#" className="gla_news_block">
                    <span className="gla_news_img">
                      <img src="assets/Diamond Package.png" />
                    </span>
                    <span className="gla_news_title">Diamond Package</span>
                    <p>
                      <strong>12 Months</strong>
                      <br />
                      365 (Days)
                    </p>
                    <div className="package-btn">
                      <button
                        type="button"
                        className="btn btn-default"
                        onClick={() => proceedCheckout(3)}
                      >
                        Rs. 8000{" "}
                      </button>
                    </div>
                  </a>
                </div>
                <div className="col-md-3 col-sm-6">
                  <a href="#" className="gla_news_block">
                    <span className="gla_news_img">
                      <img src="assets/Platinum Package.png" />
                    </span>
                    <span className="gla_news_title">Platinum Package</span>
                    <p>
                      <strong>Till Marriage</strong>
                      <br />
                      Till Marriage
                    </p>
                    <div className="package-btn">
                      <button
                        type="button"
                        className="btn btn-default"
                        onClick={() => proceedCheckout(4)}
                      >
                        Rs. 10000{" "}
                      </button>
                    </div>
                  </a>
                </div> */}
              {/* </div> */}
            </div>
          </section>

          <section
            className="gla_section gla_image_bck gla_fixed gla_wht_txt"
            data-stellar-background-ratio="0.8"
            data-image="assets/images/image4.jpg"
          >
            <div
              className="gla_over"
              data-color="#282828"
              data-opacity="0.4"
            ></div>

            <div className="container text-center">
              <p>
                <img
                  src="images/animations/just_wh.gif"
                  data-bottom-top="@src:images/animations/just_wh.gif"
                  height="150"
                  alt=""
                />
              </p>
              <h3>
                To know more, mail us at vivahbandhrp@gmail.com or call us at
                +91 705 860 4283
              </h3>
              <p className="gla_subtitle">
                All online services are self-assisted. Search, Shortlist and
                Send Interest.
              </p>
            </div>
          </section>

          <section className="gla_section gla_image_bck">
            <div className="container text-center">
              <p>
                <img
                  src="images/animations/flowers3.gif"
                  data-bottom-top="@src:images/animations/flowers3.gif"
                  height="130"
                  alt=""
                />
              </p>
              <h2>Matched By Vivahbandh</h2>
              <div className="gla_portfolio_no_padding grid">
                <div className="col-sm-4 gla_anim_box grid-item ceremony">
                  <div className="gla_shop_item">
                    <a href="assets/married.jpg" className="lightbox">
                      <img src="assets/couple1.jpg" alt="" />
                    </a>
                  </div>
                </div>

                <div className="col-sm-4 gla_anim_box grid-item engagement">
                  <div className="gla_shop_item">
                    <a href="assets/married.jpg" className="lightbox">
                      <img src="assets/couple2.jpg" alt="" />
                    </a>
                  </div>
                </div>

                <div className="col-sm-4 gla_anim_box grid-item ceremony">
                  <div className="gla_shop_item">
                    <a href="assets/married.jpg" className="lightbox">
                      <img src="assets/couple3.jpg" alt="" />
                    </a>
                  </div>
                </div>

                <div className="col-sm-4 gla_anim_box grid-item engagement">
                  <div className="gla_shop_item">
                    <a href="assets/married.jpg" className="lightbox">
                      <img src="assets/couple4.jpg" alt="" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section
            className="gla_section gla_image_bck gla_fixed gla_wht_txt"
            data-stellar-background-ratio="0.8"
            data-image="assets/images/image1.jpg"
          >
            <div
              className="gla_over"
              data-color="#282828"
              data-opacity="0.4"
            ></div>

            <div className="container text-center">
              <p>
                <img
                  src="images/animations/thnyou_wh.gif"
                  alt=""
                  height="200"
                  data-bottom-top="@src:images/animations/thnyou_wh.gif"
                />
              </p>
            </div>
          </section>
        </section>
        <Footer />
      </div>
    </>
  );
}

export default Home;
