// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getMessaging, onMessage } from "firebase/messaging";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDeh9whLeCf3gPfqNrurIXSkZJPZ9td09w",
  authDomain: "vivah-bandh.firebaseapp.com",
  projectId: "vivah-bandh",
  storageBucket: "vivah-bandh.appspot.com",
  messagingSenderId: "751314924020",
  appId: "1:751314924020:web:faeab407765a56b9dc09a1",
  measurementId: "G-WNW1EJR741",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth();
export const messaging = getMessaging();

onMessage(messaging, (payload) => {
  console.log("Message received. ", payload);
  localStorage.setItem(
    "chatUser",
    JSON.stringify({
      firstName: payload.data.name,
      id: Number(payload.data.fromId),
    })
  );
  window.location.reload();
});
