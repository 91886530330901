import createAxiosClient from "../utils/api-utils";

class ReligionApi {
    constructor() {
        this.client = createAxiosClient();
    }
    async listReligionWithCaste() {
        return await this.client.get("/religion-masters?filter[include][]=castes")
    }
    async listGothras() {
        return await this.client.get("/vvh-gothra-masters")
    }
    async listMotherTongues() {
        return await this.client.get("/vvh-mother-tongue-masters")
    }


}
export default ReligionApi = new ReligionApi();