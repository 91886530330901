import createAxiosClient, { config } from "../utils/api-utils";
class FavouriteApi {
  constructor() {
    this.client = createAxiosClient();
  }
  async addFavourite(data) {
    const reqData = {
      profileId: data,
    };
    return await this.client.post("/vvh-favourites", reqData, config());
  }

  async listFavourite() {
    return await this.client.get("/vvh-favourite-profile", config());
  }
}
export default FavouriteApi = new FavouriteApi();
