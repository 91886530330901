import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Home from "./View/home/Home";
import About from "./View/about/About";
import Contact from "./View/contact/Contact";
import PrivacyPolicy from "./View/privacy-policy/PrivacyPolicy";
import TermsConditions from "./View/terms-conditions/TermsConditions";
import CancellationRefund from "./View/cancellation-refund/CancellationRefund";
import Login from "./View/login/Login";
import Registration from "./View/registration/Registration";
import Upgrade from "./View/upgrade/Upgrade";
import UpdateProfile from "./View/update-profile/UpdateProfile";
import AccountSettings from "./View/account-settings/AccountSettings";
import SearchResults from "./View/search/SearchResults.js";
import Profile from "./View/profile/Profile";
import Filter from "./View/filter/Filter";
import Upload from "./View/upload/Upload";
import Intrest from "./View/intrest/Intrest";
import UserProfile from "./View/userProfile/userProfile";
import Checkout from "./View/checkout/Checkout";
import Favourite from "./View/favourite/Favourite.js";
import ChattingPage from "./Components/ChattingPage/ChattingPage.js";
import { app } from "./firebase";

// Admin Component
import AdminLogin from "./admin/login/Login.js";
import Dashboard from "./admin/dashboard/Dashboard.js";
import Users from "./admin/users/Users.js";
import Import from "./admin/import/Import.js";

// import { getMessaging, getToken } from "firebase/messaging";
// import { messaging } from "./firebase.js";
import $ from "jquery";

function App() {
  // const requestForToken = async () => {
  //   try {
  //     console.log("tokencall");
  //     const currentToken = await getToken(messaging, {
  //       vapidKey: process.env.VAPID_KEY,
  //     });

  //     if (currentToken) {
  //       console.log("current token for client: ", currentToken);
  //       // Perform any other neccessary action with the token
  //     } else {
  //       // Show permission request UI
  //       console.log(
  //         "No registration token available. Request permission to generate one."
  //       );
  //     }
  //   } catch (error) {
  //     console.log("An error occurred while retrieving token. ", error);
  //   }
  // };

  useEffect(() => {
    /* Mobile Menu */
    // requestForToken();
    const onBackButtonEvent = () => {
      $(".gla_main_menu").on("click", function (e) {
        $(this).next(".gla_main_menu_content").toggleClass("active");
        $(this)
          .next()
          .next(".gla_main_menu_content_menu")
          .toggleClass("active");
        $(this).toggleClass("active");
      });

      /* Section Background */
      $(".gla_image_bck").each(function () {
        var image = $(this).attr("data-image");
        var gradient = $(this).attr("data-gradient");
        var color = $(this).attr("data-color");
        var blend = $(this).attr("data-blend");
        var opacity = $(this).attr("data-opacity");
        var position = $(this).attr("data-position");
        var height = $(this).attr("data-height");
        if (image) {
          $(this).css("background-image", "url(" + image + ")");
        }
        if (gradient) {
          $(this).css("background-image", gradient);
        }
        if (color) {
          $(this).css("background-color", color);
        }
        if (blend) {
          $(this).css("background-blend-mode", blend);
        }
        if (position) {
          $(this).css("background-position", position);
        }
        if (opacity) {
          $(this).css("opacity", opacity);
        }
        if (height) {
          $(this).css("height", height);
        }
      });

      /*Animation Block Delay*/

      $("div[data-animation=animation_blocks]").each(function () {
        var i = 0;
        $(this)
          .find(".gla_icon_box, .skill-bar-content, .gla_anim_box")
          .each(function () {
            $(this).css("transition-delay", "0." + i + "s");
            i++;
          });
      });

      /* Fixed for Parallax */
      $(".gla_fixed").css("background-attachment", "fixed");

      $(".gla_search_parent").on({
        mouseenter: function () {
          $(this).find("ul").addClass("active");
        },
        mouseleave: function () {
          $(this).find("ul").removeClass("active");
        },
      });

      $(".gla_user_profile").on({
        mouseenter: function () {
          $(this).find("ul").addClass("active");
        },
        mouseleave: function () {
          $(this).find("ul").removeClass("active");
        },
      });

      /*Scroll Effect*/
      $(".gla_go").on("click", function (e) {
        var anchor = $(this);
        $("html, body")
          .stop()
          .animate(
            {
              scrollTop: $(anchor.attr("href")).offset().top,
            },
            300
          );
        e.preventDefault();
      });

      /* Mobile Menu */

      $(".gla_main_menu_content_menu .gla_parent").on("click", function (e) {
        $(this).find("ul").slideToggle(300);
      });
      $(".gla_mobile_menu").on("click", function (e) {
        $(this).toggleClass("active");
        $(".gla_mobile_menu_hor").toggleClass("active");
      });
      $(".gla_header_search span").on("click", function (e) {
        $(this).next(".gla_header_search_cont").fadeToggle();
      });
    };
    onBackButtonEvent();
    window.addEventListener("popstate", onBackButtonEvent);
    return () => {
      window.removeEventListener("popstate", onBackButtonEvent);
    };
  }, []);

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact-us" element={<Contact />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-conditions" element={<TermsConditions />} />
          <Route path="/cancellation-refund" element={<CancellationRefund />} />
          <Route path="/login" element={<Login />} />
          <Route path="/registration" element={<Registration />} />
          <Route path="/update" element={<Upgrade />} />
          <Route path="/update-profile" element={<UpdateProfile />} />
          <Route path="/account-settings" element={<AccountSettings />} />
          <Route path="/search-result" element={<SearchResults />} />
          <Route path="/profile/:id" element={<Profile />} />
          <Route path="/search" element={<Filter />} />
          <Route path="/file-upload" element={<Upload />} />
          <Route path="/request" element={<Intrest />} />
          <Route path="/my-profile" element={<UserProfile />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/favourite" element={<Favourite />} />
          <Route path="/chatting" element={<ChattingPage />} />
          {/* <Route path="/Chatting" element={<ch />} /> */}

          {/* Admin Route */}
          <Route path="/admin" element={<AdminLogin />} />
          <Route path="/admin/dashboard" element={<Dashboard />} />
          <Route path="/admin/users" element={<Users />} />
          <Route path="/admin/import" element={<Import />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
