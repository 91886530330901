import "./Upgrade.css";
import Header from "../../Components/Shared/Header/Header";
import Footer from "../../Components/Shared/Footer/Footer";
import PlanPurchase from "../plan/PlanPurchase";
function Upgrade() {
  // const proceedCheckout = (plan) => {
  //   sessionStorage.setItem("plan", plan);
  //   window.location.href = "/checkout";
  // };

  return (
    <>
      <div classNameName="gla_page" id="gla_page">
        <a href="#gla_page" classNameName="gla_top ti ti-angle-up gla_go"></a>
        <Header />
        <div
          className="gla_page_title gla_image_bck gla_wht_txt"
          data-color="#282828"
        >
          <div className="container text-left">
            <div className="row">
              <div className="col-md-8">
                <h1 className="gla_h1_title">Update</h1>
              </div>
              <div className="col-md-4">
                <div className="breadcrumbs">
                  <a href="#">Home</a>
                  <span>Update</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section id="gla_content" className="gla_content">
          <section className="gla_section">
            <div className="container text-center">
              <p>
                <img
                  src="images/animations/flowers2.gif"
                  data-bottom-top="@src:images/animations/flowers2.gif"
                  height="150"
                  alt=""
                />
              </p>
              <h3 className="gla_subtitle mt-2">
                Upgrade your membership to connect with those you like.
              </h3>
              <p>
                Search your perfect life-partner from many of trusted &
                authentic profiles.
              </p>
              <PlanPurchase />
              {/* <div className="gla_icon_boxes row text-left">
                <div className="col-md-3 col-sm-6">
                  <a href="#" className="gla_news_block">
                    <span className="gla_news_img">
                      <img src="assets/Silver Package.png" />
                    </span>
                    <span className="gla_news_title">Silver Packaged</span>
                    <p>
                      <strong>3 Months</strong>
                      <br />
                      90 (Days)
                    </p>
                    <div className="package-btn">
                      <button
                        type="button"
                        onClick={() => proceedCheckout(1)}
                        className="btn btn-default"
                      >
                        Rs. 4000{" "}
                      </button>
                    </div>
                  </a>
                </div>

                <div className="col-md-3 col-sm-6">
                  <a href="#" className="gla_news_block">
                    <span className="gla_news_img">
                      <img src="assets/Gold Package.png" />
                    </span>
                    <span className="gla_news_title">Gold Package</span>
                    <p>
                      <strong>6 Months</strong>
                      <br />
                      180 (Days)
                    </p>
                    <div className="package-btn">
                      <button
                        type="button"
                        onClick={() => proceedCheckout(2)}
                        className="btn btn-default"
                      >
                        Rs. 6000{" "}
                      </button>
                    </div>
                  </a>
                </div>

                <div className="col-md-3 col-sm-6">
                  <a href="#" className="gla_news_block">
                    <span className="gla_news_img">
                      <img src="assets/Diamond Package.png" />
                    </span>
                    <span className="gla_news_title">Diamond Package</span>
                    <p>
                      <strong>12 Months</strong>
                      <br />
                      365 (Days)
                    </p>
                    <div className="package-btn">
                      <button
                        type="button"
                        onClick={() => proceedCheckout(3)}
                        className="btn btn-default"
                      >
                        Rs. 8000{" "}
                      </button>
                    </div>
                  </a>
                </div>
                <div className="col-md-3 col-sm-6">
                  <a href="#" className="gla_news_block">
                    <span className="gla_news_img">
                      <img src="assets/Platinum Package.png" />
                    </span>
                    <span className="gla_news_title">Platinum Package</span>
                    <p>
                      <strong>Till Marriage</strong>
                      <br />
                      Till Marriage
                    </p>
                    <div className="package-btn">
                      <button
                        type="button"
                        onClick={() => proceedCheckout(4)}
                        className="btn btn-default"
                      >
                        Rs. 10000{" "}
                      </button>
                    </div>
                  </a>
                </div>
              </div> */}
            </div>
          </section>
        </section>

        <Footer />
      </div>
    </>
  );
}

export default Upgrade;
