import createAxiosClient, { config } from "../utils/api-utils";

class ProfileApi {
  constructor() {
    this.client = createAxiosClient();
  }
  async listProfile() {
    return await this.client.get("/vvh-profile-masters");
  }

  async getProfilebyId(id) {
    let filter = {
      include: [
        "user",
        "caste",
        "religion",
        "motherTongue",
        "profession",
        "qualification",
        "city",
        "state",
        "height",
        "maritalStatus",
        "income",
        "gothra",
        "familyValues",
        "familyStatus",
        "bodyType",
        "healthInformation",
        "disability",
        "maritalStatus",
        "bloodGroup",
        "professionType",
        "familyState",
        "familyCity",
        "motherOccupation",
        "fatherOccupation",
      ],
    };
    return await this.client.get(
      `/profile/${id}?filter=${JSON.stringify(filter)}`,
      config()
    );
  }

  async deactivateProfile(data) {
    return await this.client.patch("/profile-deactivate", data, config());
  }
}
export default ProfileApi = new ProfileApi();
