import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import VivApi from "../../../api";
import { useForm, Controller } from "react-hook-form";
import { getUniqueRes } from "../../../utils/api-utils";
const Search = () => {
  const { register, handleSubmit, formState, control, setValue, watch, reset } =
    useForm();

  let watchval = watch();
  const [profile, setProfile] = useState({});
  const [caste, setcaste] = useState([]);
  const [city, setCity] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    const init = async () => {
      const res = await VivApi.profile.listProfile();
      console.log(res, "check");
      setProfile(res.data);

      const userFilter = await VivApi.filter.getFilter();

      if (res.data.religions && userFilter.data?.religion) {
        // set castes
        const religion = res.data.religions.find(
          (r) => r.sid === userFilter.data?.religion
        );
        setcaste(religion.castes);
      }

      // set Cities
      if (res.data.states && userFilter.data.state) {
        const stateArr = res.data.states.filter((s) =>
          userFilter.data.state.includes(s.sid)
        );
        const cityArr = stateArr.map((s) => s.cities);
        setCity(cityArr.flat());
      }
      if (
        !userFilter.data.maritalStatus ||
        userFilter.data.maritalStatus.length === 0
      ) {
        userFilter.data.maritalStatus = [1];
      }
      reset(userFilter.data);
    };
    init();
  }, []);

  const agef = (age) => {
    const ageOptions = [];
    for (let i = age + 1; i <= 100; i++) {
      ageOptions.push({
        value: i,
        label: i.toString(),
      });
    }
    return ageOptions;
  };

  const submitForm = async (data) => {
    try {
      const newData = getUniqueRes(data);
      await VivApi.filter.saveFilter(newData);
      window.location.href = "/search-result";
    } catch (error) {
      console.error("Error Saving", error);
    }
  };

  const resetValue = () => {
    reset({
      minAge: "",
      maxAge: "",
    });
  };

  return (
    <form onSubmit={handleSubmit(submitForm)}>
      <div className="row">
        <div className="col-md-4">Age</div>
        <div className="col-md-4">
          <Select
            options={agef(17)}
            onChange={(e) => setValue("minAge", e.value)}
            value={agef(17).find((e) => e.value == watchval["minAge"])}
            placeholder="Min"
          />
        </div>
        <div className="col-md-4">
          <Select
            isDisabled={!watchval["minAge"]}
            options={agef(watchval["minAge"])}
            value={agef(17).find((e) => e.value == watchval["maxAge"])}
            onChange={(e) => setValue("maxAge", e.value)}
            placeholder="Max"
          />
        </div>
      </div>
      <div className="row mt-1">
        <div className="col-md-4">Height</div>
        <div className="col-md-4">
          <Select
            options={profile.height}
            getOptionLabel={(o) => o.height}
            getOptionValue={(o) => o.sid}
            // onChange={(e) => field.onChange(setValue('height', e.sid))}
            onChange={(e) => setValue("minHeight", e.sid)}
            value={
              profile.height &&
              profile.height.find((e) => e.sid === watchval["minHeight"])
            }
            placeholder="Min"
          />
        </div>
        <div className="col-md-4">
          <Select
            options={profile.height}
            getOptionLabel={(o) => o.height}
            getOptionValue={(o) => o.sid}
            // onChange={(e) => field.onChange(setValue('height', e.sid))}
            onChange={(e) => setValue("maxHeight", e.sid)}
            value={
              profile.height &&
              profile.height.find((e) => e.sid === watchval["maxHeight"])
            }
            placeholder="Max"
          />
        </div>
      </div>
      <div className="row mt-1">
        <div className="col-md-4">Marital Status</div>
        <div className="col-md-8">
          <Select
            options={profile.maritalStatus}
            getOptionLabel={(o) => o.maritalStatus}
            getOptionValue={(o) => o.sid}
            onChange={(e) =>
              setValue(
                "maritalStatus",
                e.map((e) => e.sid)
              )
            }
            value={
              profile.maritalStatus &&
              watchval["maritalStatus"] &&
              watchval["maritalStatus"].map((val) =>
                profile.maritalStatus.find((e) => e.sid === val)
              )
            }
            isMulti
            className="basic-multi-select"
            classNamePrefix="select"
            isClearable={true}
          />
        </div>
      </div>
      <div className="row mt-1">
        <div className="col-md-4">Religion</div>
        <div className="col-md-8">
          <Select
            options={profile.religions}
            getOptionLabel={(r) => r.name}
            getOptionValue={(r) => r.sid}
            isClearable={true}
            // onChange={(e) => field.onChange(setValue('height', e.sid))}
            onChange={(e) => {
              setValue("religion", e ? e.sid : null);
              // let casteArr = e.map((e) => e.castes)
              // const mergedArray = Array.prototype.concat.apply([], casteArr)
              // const mergedArray = casteArr.flat(1)
              setcaste(e ? e.castes : null);
            }}
            value={
              profile.religions &&
              profile.religions.find((e) => e.sid === watchval["religion"])
            }
            classNamePrefix="select"
          />
          {/* <Select options={options} isMulti className="basic-multi-select" classNamePrefix="select" /> */}
        </div>
      </div>
      <div className="row mt-1">
        <div className="col-md-4">Caste</div>
        <div className="col-md-8">
          <Select
            options={caste}
            getOptionLabel={(o) => o.name}
            getOptionValue={(o) => o.sid}
            // onChange={(e) => field.onChange(setValue('height', e.sid))}
            onChange={(e) =>
              setValue(
                "caste",
                e.map((e) => e.sid)
              )
            }
            value={
              caste &&
              watchval["caste"] &&
              watchval["caste"].map((val) => caste.find((e) => e.sid === val))
            }
            isMulti
            className="basic-multi-select"
            classNamePrefix="select"
          />
          {/* <Select options={caste} isMulti className="basic-multi-select" classNamePrefix="select" /> */}
        </div>
      </div>
      <div className="row mt-1">
        <div className="col-md-4">Mother Tongue</div>
        <div className="col-md-8">
          <Select
            options={profile.motherTongues}
            getOptionLabel={(o) => o.name}
            getOptionValue={(o) => o.sid}
            onChange={(e) => setValue("motherTongue", e ? e.sid : null)}
            isClearable
            value={
              profile.motherTongues &&
              profile.motherTongues.find(
                (e) => e.sid === watchval["motherTongue"]
              )
            }
            // isMulti className="basic-multi-select" classNamePrefix="select"
          />
          {/* <Select options={options} isMulti className="basic-multi-select" classNamePrefix="select" /> */}
        </div>
      </div>
      <div className="row mt-1">
        <div className="col-md-4">State Live In</div>
        <div className="col-md-8">
          <Select
            options={profile.states}
            getOptionLabel={(r) => r.name}
            getOptionValue={(r) => r.sid}
            onChange={(e) => {
              setValue(
                "state",
                e.map((e) => e.sid)
              );
              let cityArr = e.map((e) => e.cities);
              // const mergedArray = Array.prototype.concat.apply([], casteArr)
              const mergedArray = cityArr.flat(1);
              setCity(mergedArray);
            }}
            value={
              profile.states &&
              watchval["state"] &&
              watchval["state"].map((val) =>
                profile.states.find((e) => e.sid === val)
              )
            }
            isMulti
            className="basic-multi-select"
            classNamePrefix="select"
          />
          {/* <Select options={options} isMulti className="basic-multi-select" classNamePrefix="select" /> */}
        </div>
      </div>
      <div className="row mt-1">
        <div className="col-md-4">City / District</div>
        <div className="col-md-8">
          <Select
            options={city}
            getOptionLabel={(o) => o.name}
            getOptionValue={(o) => o.sid}
            // onChange={(e) => field.onChange(setValue('height', e.sid))}
            onChange={(e) =>
              setValue(
                "city",
                e.map((e) => e.sid)
              )
            }
            value={
              city &&
              watchval["city"] &&
              watchval["city"].map((val) => city.find((e) => e.sid === val))
            }
            isMulti
            className="basic-multi-select"
            classNamePrefix="select"
          />
          {/* <Select options={options} isMulti className="basic-multi-select" classNamePrefix="select" /> */}
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-md-8 col-md-offset-4 search-btn">
          <input type="submit" className="btn submit" value="Search" />
          <input
            type="button"
            onClick={() => resetValue()}
            className="btn btn-danger ml-3"
            value="Reset"
          />
        </div>
      </div>
    </form>
  );
};

export default Search;
