import createAxiosClient, { config } from "../utils/api-utils";
class ContactApi {
  constructor() {
    this.client = createAxiosClient();
  }

  async addContact(data) {
    return await this.client.post("/vvh-contacts", data);
  }
}
export default ContactApi = new ContactApi();
