import React, { useState } from "react";
import "./Dashboard.css";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import axios from "axios";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { getMessaging, getToken } from "firebase/messaging";
import { messaging } from "../../firebase";
import VivahApi from "../../api";
function Dashboard() {
  const navigate = useNavigate();

  return (
    <>
      <div className="gla_page" id="gla_page">
        <a href="#gla_page" className="gla_top ti ti-angle-up gla_go"></a>
        <Header />
        <div
          className="gla_page_title gla_image_bck gla_wht_txt"
          data-color="#282828"
        >
          <div className="container text-left">
            <div className="row">
              <div className="col-md-8">
                <h1 className="gla_h1_title">Dashboard</h1>
              </div>
            </div>
          </div>
        </div>
        <section id="gla_content" className="gla_content">
          <section className="gla_section">
            <div className="dashboard">
              <div className="row">
                <div className="col-md-2 sidebar col-xs-12">
                   <div className="side_header">
                      <img src="/assets/images/profile.png" alt="" />
                      Admin Name
                   </div>
                   <ul className="">
                    <li><a href="/admin/dashboard">Dashboard</a></li>
                    <li><a href="/admin/users">Users</a></li>
                    <li><a href="/admin/import">Import Data</a></li>
                    <li><a href="/admin/settings">Settings</a></li>  
                  </ul> 
                </div>
                <div className="col-md-10 col-xs-12 dashboard_container">
                    <div className="panel">
                        <div className="title">Total Users</div>
                        <div className="count">500</div>
                    </div>
                    <div className="panel">
                        <div className="title">Male</div>
                        <div className="count">300</div>
                    </div>
                    <div className="panel">
                        <div className="title">Female</div>
                        <div className="count">200</div>
                    </div>
                    <div className="panel">
                        <div className="title">Paid Users</div>
                        <div className="count">500</div>
                    </div>
                    <div className="panel">
                        <div className="title">Unpaid Users</div>
                        <div className="count">500</div>
                    </div>
                </div>
              </div>
            </div>
          </section>
        </section>
        <Footer />
      </div>
    </>
  );
}

export default Dashboard;
