import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import OwlCarousel from 'react-owl-carousel';
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import './Slider.css';

function Slider() {
   
  
    return (
    <div className="gla_slider gla_wht_txt">
            <OwlCarousel className='gla_slider_carousel' 
                    loop={true}
                    margin={10} 
                    nav
                    items={1}
                    autoplay={true}
                    >
                    {/* <div className="item gla_slider">
                        <img src="assets/images/image3.jpg" />
                        <div className="container">
                            <div className="gla_slide_txt gla_slide_center_middle text-center">
                                <div className="gla_slide_title">Kelly & Rob</div>
                            </div>
                        </div>
                    </div>
                    <div className="item gla_slider">
                        <img src="assets/images/image6.jpg" />
                        <div className="container">
                            <div className="gla_slide_txt gla_slide_center_middle text-center">
                                <div className="gla_slide_title">Kelly & Rob</div>
                            </div>
                        </div>
                    </div> */}
                    <div className="item gla_slider">
                        <img src="assets/images/image8.jpg" />
                        <div className="container">
                            <div className="gla_slide_txt gla_slide_center_middle text-center">
                                <div className="gla_slide_title">Kelly & Rob</div>
                            </div>
                        </div>
                    </div>
                    <div className="item gla_slider">
                        <img src="assets/images/image7.jpg" />
                        <div className="container">
                            <div className="gla_slide_txt gla_slide_center_middle text-center">
                                <div className="gla_slide_title">Kelly & Rob</div>
                            </div>
                        </div>
                    </div>
                    <div className="item gla_slider">
                        <img src="assets/images/image2.jpg" />
                        <div className="container">
                            <div className="gla_slide_txt gla_slide_center_middle text-center">
                                <div className="gla_slide_title">Kelly & Rob</div>
                            </div>
                        </div>
                    </div>  
                  {/* <div className="gla_slider gla_image_bck item">
                        <div className="gla_over"></div>
                        <div className="container">
                            <div className="gla_slide_txt gla_slide_center_middle text-center">
                                <div className="gla_slide_title">Kelly & Rob</div>
                            </div>
                        </div>
                    </div>  
                    <div className="gla_slider gla_image_bck item" >
                        <div className="gla_over"></div>
                        <div className="container">
                            <div className="gla_slide_txt gla_slide_center_middle text-center">
                                <div className="gla_slide_title">Save the Date<br />August 10, 2017</div>
                            </div>
                        </div>
                    </div> */}
            </OwlCarousel>
        <a className="gla_scroll_down gla_go" href="#gla_content">
            <b></b>
            Scroll
        </a>
    </div>
  );
}

export default Slider;
