import './TermsConditions.css';
import Header from '../../Components/Shared/Header/Header';
import Footer from '../../Components/Shared/Footer/Footer';

function TermsConditions() {
  return (
      <>
        <div classNameName="gla_page" id="gla_page">
            <a href="#gla_page" classNameName="gla_top ti ti-angle-up gla_go"></a>
            <Header />
            <div className="gla_page_title gla_image_bck gla_wht_txt" data-color="#282828">
                <div className="container text-left">
                <div className="row">
                    
                    <div className="col-md-8">
                        <h1 className="gla_h1_title">TERMS AND CONDITIONS</h1>
                    </div>
                    
                    <div className="col-md-4">
                        <div className="breadcrumbs">
                            <a href="/">Home</a><span>TERMS AND CONDITIONS</span>
                        </div>
                    </div>
                </div>
                </div>
            </div>
<section id="gla_content" className="gla_content">
<section className="gla_section">
<div className="container">

<div className="row">
    <div className="col-md-12 col-xs-12">   
        <div className="gla_post_info">
            TERMS AND CONDITIONS
        </div> 
        <ul>
            <li>The organization will not be responsible for verifying the information uploaded on the website.</li>
            <li>The organization is only indicative of bride or groom. The rest of the personal information and all further conversations should be done at your level with the consent of the bride or groom or related families or individuals should do it at own risk.</li>
            <li>Verify the information from the bride or groom yourself otherwise the organization will not be responsible in future.</li>
            <li>The organization only acts as a bride or groom indicator but by actually verifying the choice and marriage will be the responsibility of the family or individuals concerned.</li>
            <li>The organization only accepts donations and no service is charged.</li>
            <li>No argument will be acceptable to the organization as there may be delay in suggesting the place considering the caste, financial status, age and physical condition locations and many etc.</li>
            <li>Clicking by AGREE button, It means all the turm and conditions criteria of society names as SREE CHHATRPATI SAMBHAJI RAJE BAHUDHHESHIY SANSTHA as per its commety membars are accepted and you are giving the amount ( donations ) given to the organization voluntarily and with understanding all the rules in the form of donation. You have no any objection to that.</li>
            <li>Understanding all the above conditions of the organization, you are voluntarily giving the following without any expectation from the organization and you will not have any objection in the future regarding the work of the organization.</li>
            <li>Aware that Vivah Bandh Marriage Bureau is a social enterprise under Chhatrapati Sambhaji Raje Multipurpose Institution.</li>
            <li>Society monitors by government charitable commissions accounting officers by per yearly manners. Suggestions of bride or groom is not Business for society, its social services for society And society improve services day by day by the valuable support and suggestions, society always welcome for such supporters. We gets energy to work much more because of such acceptance.</li>
        </ul>
    </div>
</div>  
</div>
</section>
</section> 

            <Footer />
        </div>
      </>
  );
}

export default TermsConditions;
