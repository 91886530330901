import Auth from "./Auth";
import Religion from "./Religion";
import profile from "./Profile";
import filter from "./Filter";
import Search from "./Search";
import Interest from "./Interest";
import Plan from "./Plan";
import FirebaseToken from "./FirebaseToken";
import Contact from "./Contact";
import UploadPic from "./UploadPic";
import Favourite from "./Favourite";
import Chat from "./Chat";

const API = {
  Auth,
  Religion,
  profile,
  filter,
  Search,
  Interest,
  Plan,
  FirebaseToken,
  Contact,
  UploadPic,
  Favourite,
  Chat,
};

export default API;
