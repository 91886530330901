import "./Contact.css";
import Header from "../../Components/Shared/Header/Header";
import Footer from "../../Components/Shared/Footer/Footer";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { data } from "jquery";
import VivahApi from "../../api";
function Contact() {
  const formSchema = Yup.object().shape({
    email: Yup.string()
      .required("email is required")
      .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/i, "Enter Valid Email"),
    phone: Yup.string()
      .required("Phone Number is required")
      .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        "Enter Valid Phone Number"
      ),
    name: Yup.string().required("Name is required"),
  });

  const formOptions = { resolver: yupResolver(formSchema) };
  const { register, handleSubmit, formState, watch } = useForm(formOptions);
  const { errors } = formState;

  const watchVals = watch();
  const onSubmit = async (data) => {
    const resUser = await VivahApi.Contact.addContact(data);
    alert("thank You");
  };
  return (
    <>
      <div classNameName="gla_page" id="gla_page">
        <a href="#gla_page" classNameName="gla_top ti ti-angle-up gla_go"></a>
        <Header />
        <div
          className="gla_page_title gla_image_bck gla_wht_txt"
          data-color="#282828"
        >
          <div className="container text-left">
            <div className="row">
              <div className="col-md-8">
                <h1 className="gla_h1_title">Contact Us</h1>
              </div>
              <div className="col-md-4">
                <div className="breadcrumbs">
                  <a href="#">Home</a>
                  <span>Contact Us</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section id="gla_content" className="gla_content">
          <section className="gla_section">
            <div className="container text-center">
              <p>
                <img
                  src="images/animations/rsvp.gif"
                  data-bottom-top="@src:images/animations/rsvp.gif"
                  height="180"
                  alt=""
                />
              </p>
              <div className="row">
                <div className="col-md-8 col-md-push-2">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                      <div className="col-md-4">
                        <label>Your name*</label>
                        <input
                          type="text"
                          name="name"
                          {...register("name")}
                          className={`form-control form-opacity ${
                            errors.name ? "is-invalid" : ""
                          }`}
                        />
                        <span className="invalid-feedback">
                          {errors.name?.message}
                        </span>
                      </div>
                      <div className="col-md-4">
                        <label>Your e-mail*</label>
                        <input
                          type="text"
                          name="email"
                          {...register("email")}
                          className={`form-control form-opacity ${
                            errors.email ? "is-invalid" : ""
                          }`}
                        />
                        <span className="invalid-feedback">
                          {errors.email?.message}
                        </span>
                      </div>
                      <div className="col-md-4">
                        <label>Your Phone</label>
                        <input
                          type="text"
                          name="phone"
                          {...register("phone")}
                          className={`form-control form-opacity ${
                            errors.phone ? "is-invalid" : ""
                          }`}
                        />
                        <span className="invalid-feedback">
                          {errors.phone?.message}
                        </span>
                      </div>
                      <div className="col-md-12">
                        <label>Notes</label>
                        <textarea
                          type="text"
                          name="notes"
                          {...register("notes")}
                          className="form-control form-opacity"
                        ></textarea>
                      </div>
                      <div className="col-md-12">
                        <input
                          type="submit"
                          className="btn submit"
                          value="Send"
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </section>
        <section
          id="grey"
          className="gla_section gla_image_bck gla_wht_txt"
          data-color="#292929"
        >
          <div className="container">
            <div className="row">
              <div className="col-md-4 col-sm-4">
                <h4>Address</h4>
                <p>
                  Address: Desale Pada, Dombivali East, Thane, Maharashtra
                  421204
                  <br />
                  Call us: 1.777.77.777
                  <br />
                  <a href="mailto:mail@vivahbandhrp.com">
                    mail@vivahbandhrp.com
                  </a>
                </p>
              </div>
              <div className="col-md-4 col-sm-4">
                <h4>Our Office Working Hours</h4>
                <ul className="list-unstyled">
                  <li>
                    <strong>Monday - Friday:</strong> 10am to 6pm
                  </li>
                  <li>
                    <strong>Saturday:</strong> 10am to 2pm
                  </li>
                  <li>
                    <strong>Sunday:</strong> Closed
                  </li>
                </ul>
              </div>
              <div className="col-md-4 col-sm-4">
                <h4>Visit Us</h4>
                <div className="gla_twitter">
                  We always love to hear from our customers! We are happy to
                  answer your questions and assist you
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
}

export default Contact;
