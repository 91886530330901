import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "react-chat-elements/dist/main.css";
import "./Chatting.css";
import { useLocation } from "react-router-dom";
// Chat Component
import { ChatList } from "react-chat-elements";
import { MessageList } from "react-chat-elements";
import { Input } from "react-chat-elements";
import VivahApi from "../../api";

function Chatting({ chatUser }) {
  const location = useLocation();
  const userName = location.state && location.state.data;
  const [toggle, setToggle] = useState(false);
  const [msgText, setMsgText] = useState("");
  const [msgData, setMsgData] = useState([]);
  const [showChat, setChat] = useState(false);
  // const refreshPage = () => {
  //   setTimeout(() => {
  //     window.location.reload(false);
  //   }, 0);
  // };

  const handleToggle = () => {
    setToggle(!toggle);
  };

  const handleChatUser = (obj) => {
    setChat(true);
    // console.log('Chat Obje', obj);
  };

  const backToChat = () => {
    setChat(false);
  };

  useEffect(() => {
    const init = async () => {
      const msgData = await VivahApi.Chat.getMsgByUser(chatUser);
      // console.log(msgData.data, "text details");

      setMsgData(msgData.data);
    };

    init();
  }, []);

  const handleMessageSend = async () => {
    if (!msgText) return;
    const msgObj = {
      position: "right",
      type: "text",
      title: "You",
      text: msgText,
    };

    console.log("Sending message ...", msgText);

    setMsgData([...msgData, msgObj]);
    // TODO - Add send Message API call passing and Defining msg Object
    const chat = {
      receiverId: chatUser.id,
      text: msgText,
    };
    const mesgData = await VivahApi.Chat.addMsg(chat);
    setMsgText("");
  };

  return (
    <>
      <div
        className="chatting"
        style={toggle ? { height: "47px" } : { height: "auto" }}
      >
        <div className="chat_heading">
          <div>{chatUser.firstName}</div>
          <button className="chat_arrrow" onClick={handleToggle}>
            {toggle ? <>&#11205;</> : <>&#11206;</>}
          </button>
        </div>
        <div
          className="chat_body"
          style={toggle ? { display: "none" } : { display: "block" }}
        >
          <div className="chat">
            {/* {!showChat ? (
              <ChatList
                className="chat-list"
                dataSource={[
                  {
                    avatar:
                      "https://avatars.githubusercontent.com/u/80540635?v=4",
                    alt: "kursat_avatar",
                    title: "Kursat",
                    subtitle:
                      "Why don't we go to the No Way Home movie this weekend ?",
                    date: new Date(),
                    unread: 3,
                  },
                  {
                    avatar:
                      "https://avatars.githubusercontent.com/u/80540635?v=4",
                    alt: "kursat_avatar",
                    title: "John",
                    subtitle:
                      "Why don't we go to the No Way Home movie this weekend ?",
                    date: new Date(),
                    unread: 2,
                  },
                  {
                    avatar:
                      "https://avatars.githubusercontent.com/u/80540635?v=4",
                    alt: "kursat_avatar",
                    title: "Mickel",
                    subtitle:
                      "Why don't we go to the No Way Home movie this weekend ?",
                    date: new Date(),
                    unread: 1,
                  },
                  {
                    avatar:
                      "https://avatars.githubusercontent.com/u/80540635?v=4",
                    alt: "kursat_avatar",
                    title: "Jay",
                    subtitle:
                      "Why don't we go to the No Way Home movie this weekend ?",
                    date: new Date(),
                    unread: 3,
                  },
                ]}
                onClick={handleChatUser}
              />
            ) : (
              <MessageList
                className="message-list"
                lockable={true}
                toBottomHeight={"100%"}
                dataSource={[
                  {
                    position: "left",
                    type: "text",
                    title: "Kursat",
                    text: "Give me a message list example !",
                  },
                  {
                    position: "right",
                    type: "text",
                    title: "Emre",
                    text: "That's all.",
                  },
                ]}
              />
            )} */}
            <MessageList
              className="message-list"
              lockable={true}
              toBottomHeight={"100%"}
              dataSource={msgData}
            />
          </div>
          {/* {showChat && (
            <footer className="chat_footer">
              <input type="text" placeholder="Type message here..." />
              <button style={{ marginRight: "5px" }}>Send</button>
              <button onClick={backToChat}>Back</button>
            </footer>
          )} */}
          <footer className="chat_footer">
            <input
              value={msgText}
              type="text"
              placeholder="Enter message here..."
              onChange={(e) => setMsgText(e.target.value)}
            />
            <button style={{ marginRight: "5px" }} onClick={handleMessageSend}>
              Send
            </button>
            {/* <button onClick={backToChat}>Back</button> */}
          </footer>
        </div>
      </div>
    </>
  );
}

export default Chatting;
